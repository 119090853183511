import React, { Component } from 'react';
import DataService from '../../DataService/DataService';
import Loader from '../../Components/Loader/Loader';
import noProjectsIcon from '../../Assets/images/no-projects.svg';
import loadingGears from '../../Assets/images/loading-gears.svg';


class VerifyWithdraw extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            verified: false
        }
    }

    componentDidMount = () => {
        let token = this.props.match.params.token;
        if (token == undefined) {
            this.setState({ loading: false, verified: false, failReason: "No Token Found (Malformed Url)" });
        } else {
            DataService.withdrawVerify(token)
                .then(res => {
                    if (res.data.statusCode == 200) {
                        if (res.data.errors.length != 0) {
                            this.setState({ loading: false, verified: false, failReason: res.data.errors.map(v => v.message).toString() });
                        } else if (res.data.response.withdrawCompleted) {
                            this.setState({ loading: false, verified: true, hash: res.data.response.txHash });
                        } else {
                            this.setState({ loading: false, verified: false, failReason: "Unknown Error. Contact Support" });
                        }
                    } else {
                        this.setState({ loading: false, verified: false, failReason: "Unknown Error. Contact Support" });

                    }
                }).catch(err => this.setState({ loading: false, verified: false, failReason: err.toString() }));
        }
    }

    projectStyle = {
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        'min-height': '250px',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '30px',

    }
    render() {
        let noToken = <div style={this.projectStyle} className="col">
            <icon className="fa fa-warning" style={{ fontSize: "60px", marginTop: "20px", marginBottom: "30px" }} />
            <span style={{ fontSize: "20px", fontWeight: '500' }}>Withdraw Failed</span>
            <span>Sorry, we could not finalize your transaction. Reason: {this.state.failReason}</span>
            <div className=" button-wrapper w-80 " style={{ marginTop: "40px", marginBottom: "40px" }}>
                <button type="button" onClick={() => { this.props.history.push("/support") }} style={{ "padding": "10px 25px" }}
                    className=" btn btn-primary w-80">Go to Support</button>
            </div>
        </div>
        let yesToken = <div style={this.projectStyle} className="col">
            <icon className="fa fa-check-circle" style={{ fontSize: "60px", color: "#008787", marginTop: "20px", marginBottom: "30px" }} />
            <span style={{ fontSize: "20px", fontWeight: '500' }}>Transaction Complete!</span>
            <span>The withdrawal has been completed successfully!</span>
            <span>You will recieve a email with details of the transaction</span>
        </div>
        let loadingToken = <div style={this.projectStyle} className="col">
            <img src={loadingGears} style={{ fontSize: "60px", color: "#008787", marginTop: "20px", marginBottom: "30px" }} />
            <span style={{ fontSize: "20px", fontWeight: '500' }}>Processing Transaction</span>
            <span>We’re processing your withdraw request. Please don't refresh the browser.</span>
        </div>
        return (
            <div id="wallet-wrapper" style={{ width: "100%" }}>
                <div className="wallet-withdraw withdraw-BTC">
                    <div className="center-content w-100">
                        {this.state.loading?loadingToken:(this.state.verified ? yesToken : noToken)}
                    </div>
                </div>
            </div>);
    }
}

export default VerifyWithdraw;