import React from 'react';
import InputField from "../../Components/InputField/InputField";
import Button from "../../Components/Button/Button";
//import './ForgotPassword.css';
import { ToastContainer, toast } from 'react-toastify';
import DataService from '../../DataService/DataService';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Loader/Loader';

export default class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '' }
    }
    componentDidMount() {
        if (this.props.location.pathname.split("/").slice(-1).pop() == "tokenfail") {
            toast.warn("Re-enter your email and follow instructions");
        }
    }
    onFieldChange = e => {
        this.setState({ errEmail: false });
        let newObj = {};
        newObj[e.target.id] = e.target.value;
        this.setState(newObj);
    }
    forgot = e => {
        e.preventDefault();
        if (this.state.email == '') {
            this.setState({ errEmail: true });
            return;
        }
        this.setState({loading:true});
        DataService.forgotPassword({ email: this.state.email })
            .then(res => {
                if (res.data.statusCode == 200) {
                    localStorage.setItem("waitForReset",true);
                    this.props.history.push("/");
                } else {
                    toast.error("An unknown error just occurred");
                    this.setState({loading:false});
                }
            }).catch( err => {
                toast.error ("An unkown error just occurred");
                this.setState({loading:false});
            })

    }
    render() {
        if (this.state.loading) return <Loader />
        return (
            <div id="reset-screen" class="login-wrapper reset-login">
                <main class="reset-heading">
                    <h2>Reset Login Password</h2>
                    <form class="main-content login-form inner-wrapper" onSubmit={this.forgot}>
                        <div class="form-group">
                            <label for="resetpassword">Email or Phone Number</label>
                            <input type="text" class="form-control" id="email" onChange={this.onFieldChange} required aria-describedby="emailHelp"
                                placeholder="Email or Phone Number" />
                        </div>
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </form>
                </main>
            </div>
        );
    }
}


