import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
//import App from './App';
import * as serviceWorker from './serviceWorker';
import Index from './Routers/Index/Index';
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faSearch, faBell,faSortDown, faPlay,faTimes, faExternalLinkAlt, faFile, faUser, faLock, faBars  } from '@fortawesome/free-solid-svg-icons'
import {} from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core'
import 'font-awesome/css/font-awesome.min.css';
import './Assets/css/main.css';
import Login from './ViewComponents/Login/Login';

library.add(faBars,fab,faSearch,faBell,faPlay,faTimes,faExternalLinkAlt, faFile,faLock,faUser)

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
