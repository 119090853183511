import React from 'react';
import './FileUploader3.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fileIcon from '../../images/file-icon.png';
import fileUploadIcon from '../../images/upload-icon.png';
import DataService from '../../DataService/DataService';
import ProgressBar from '../../Components/ProgressBar/ProgressBar';
import { ToastContainer, toast } from 'react-toastify';
import docPic from "../../images/file_check.svg";

const SELECTED = '3', UPLOADING = '2', NOFILE = '1';
const NOTUPLOADED = -1;
export default class FileUploader3 extends React.Component {

    constructor(props) {
        super(props);
        let file = {

        };
        let status = NOFILE;
        let uploadedId = NOTUPLOADED;
        if (this.props.document != undefined) {
            file.size = this.props.document.fileSizeInMbs;
            file.name = this.props.document.name;
            file.fileName = this.props.document.url;
            uploadedId = this.props.document.id;
            status = SELECTED;
        }


        this.state = {
            file: file,
            status: status,
            progress: 0,
            uploadedId: uploadedId,
            fileError: ""
        };
    }
    componentDidMount() {
        window.addEventListener("dragover", function (e) {
            e = e;
            e.preventDefault();
        }, false);
        window.addEventListener("drop", function (e) {
            e = e;
            e.preventDefault();
        }, false);

        var container = document.getElementById(this.props.id);
        container.addEventListener("drop", (e) => {
            let event = e;
            this.uploadFile(event);
            e.preventDefault();
        })
    }
    displayError = error => {
        this.setState({ fileError: error }, () => {
            setTimeout(() => this.setState({ fileError: "" }), 6000);
        })
    }
    uploadFile = e => {
        if (this.state.status == UPLOADING) return;
        let file = e.target.type == "file" ? e.target.files[0] : e.dataTransfer.files[0];
        if (file.size > 5e06) {
            this.displayError("File is too big. Please upload under 5MB");
            return;
        } else if (this.props.documentConfig.allowedFileExts.split(",").find(f => f.replace(".", "") == file.name.split(".").slice(-1).pop()) == undefined) {
            this.displayError("Wrong File Type Allowed Files are " + this.props.documentConfig.allowedFileExts);
            return;
        }
        let body = {
            documentConfigurationId: this.props.id,
            file: file,
        }

        if (this.state.uploadedId != NOTUPLOADED) body.id = this.state.uploadedId;
        this.setState({ status: UPLOADING, progress: 0, file: { name: body.file.name, size: body.file.size / 1e06 } }, () => {
            DataService.uploadDocumentUser(body, this.setProgress)
                .then(res => {
                    if (res.data.statusCode == 200)
                        this.setState({ status: SELECTED, document: res.data.response, file: { name: res.data.response.name, size: res.data.response.fileSizeInMbs,fileName: res.data.response.url }, uploadedId: res.data.response.id })
                    else
                        throw "Server Reject File. Make sure to upload correct format";
                })
                .catch(err => {
                    let file = {
                    };
                    let status = NOFILE;
                    let uploadedId = NOTUPLOADED;
                    if (this.props.document != undefined) {
                        file.size = this.props.document.fileSizeInMbs;
                        file.name = this.props.document.name;
                        uploadedId = this.props.document.id;
                        status = SELECTED;
                    }


                    this.setState({
                        file: file,
                        status: status,
                        progress: 0,
                        uploadedId: uploadedId,
                    });
                    toast.error(`An error occured: ${err.toString()}`);
                });
        });
    }
    setProgress = progressEvent => {
        let ratio = progressEvent.loaded / progressEvent.total;
        let percent = ratio * 100;

        this.setState({ progress: percent.toPrecision(3), file: { ...this.state.file, size: ((this.state.file.size - (ratio * this.state.file.size))).toPrecision(3) } });

        console.log(percent.toPrecision(3));
    }
    startUpload = () => {
        //Begin Uploading File and tracking state
    }
    calculateFileSizeString(bytes) {
        if (bytes > 1e06) {
            return (bytes / 1e06).toPrecision(4) + ' MB';
        } else if (bytes > 1e03) {
            return (bytes / 1e03).toPrecision(4) + " KB";
        }
        return bytes + " Bytes"
    }
    render() {
        var selectedJSX = <div class="card-text">
            <p>{this.state.file.fileName != undefined?this.state.file.fileName.split("/").slice(-1).pop(): this.props.documentConfig.name}</p>
            <small class="text-muted">{this.state.file.size} MB {this.state.status === UPLOADING ? "remaining" : ""}</small>

        </div>
        return (
            <div class="col-md-6 col-lg-4 d-flex align-items-stretch" id={this.props.id}>
                <div class="inner-padding w-100">
                    <a  target="_blank" href={(this.state.status == SELECTED ? (this.state.document != undefined ? `https://api.fasset.com${this.state.document.url}` : `https://api.fasset.com${this.props.document.url}`) : false)} align="center">
                        <img  onClick={this.state.status == NOFILE? () => document.getElementById(`${this.props.id}btn`).click():false} class="card-img-top" style={{ "objectFit":"cover",width: '120px',objectPosition:"top" }} src={this.state.defaultIcon?docPic: (this.state.status == SELECTED  ? (this.state.document != undefined ? `https://api.fasset.com${this.state.document.thumbnail}?i=${Date.now()}` : `https://api.fasset.com${this.props.document.thumbnail}?i=${Date.now()}`) : fileUploadIcon)} alt="Card image cap" onError={() => {
                    this.setState({defaultIcon:true});
                    }}/>
                    </a>
                    <div class="card-body">
                        <h5 class="card-title">{(this.state.status != NOFILE && this.state.fileError != "")?this.state.fileError:this.props.documentConfig.title}</h5>
                        {this.state.status === UPLOADING ?
                            <span className="progressTop" style={{ width: '100%' }}>
                                <span>{this.state.progress}%</span>
                                <ProgressBar progress={this.state.progress} />
                            </span>
                            :

                            ""}
                        {this.state.status !== NOFILE ? selectedJSX : <label for={"myfile" + this.props.id} id={this.props.id+"btn"}>{this.state.fileError == "" ? "Click or drag to upload" : this.state.fileError}</label>}
                        <input type='file' style={{ display: 'none' }} onChange={this.uploadFile} id={'myfile' + this.props.id} />
                        <span class="text-center" style={{ marginTop: '25px' }}>
                            {/* <button type="submit" class="btn btn-secondary">Upload</button> */}
                            {this.state.status === SELECTED ?
                                <label className='btn btn-secondary' for={'myfile' + this.props.id} style={{ width: '100px', fontWeight: 'bold', fontSize: '13px' }}>
                                    Change File</label> : ''}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
