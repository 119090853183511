import React from 'react';
import './FileUploader2.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fileIcon from '../../images/file-icon.png';
import fileUploadIcon from '../../images/upload-icon.png';
import DataService from '../../DataService/DataService';
import ProgressBar from '../../Components/ProgressBar/ProgressBar';
import { ToastContainer, toast } from 'react-toastify';

const SELECTED = '3', UPLOADING = '2', NOFILE = '1';
const NOTUPLOADED = -1;
export default class FileUploader4 extends React.Component {

    constructor(props) {
        super(props);
        let file = {    
           
        };
        let status = NOFILE;
        let uploadedId = NOTUPLOADED;
        if (this.props.document != undefined) {
            file.size = this.props.document.fileSizeInMbs;
            file.name = this.props.document.name;
            uploadedId = this.props.document.id;
            status = SELECTED;
        }


        this.state = {
            file: file,
            status: status,
            progress: 0,
            uploadedId: uploadedId,
            fileError: "",
        };
    }
    componentDidMount() {
        window.addEventListener("dragover", function (e) {
            e = e;
            e.preventDefault();
        }, false);
        window.addEventListener("drop", function (e) {
            e = e;
            e.preventDefault();
        }, false);

        var container = document.getElementById(this.props.id);
        container.addEventListener("drop", (e) => {
            let event = e;
            this.uploadFile(event);
            e.preventDefault();
        })
    }
    displayError = error => {
        this.setState({fileError: error}, () => {
            setTimeout(() => this.setState({fileError: ""}),6000);
        })
    }
    uploadFile = e => {
        if (this.state.status == UPLOADING) return;
        let file = e.target.type == "file"? e.target.files[0]: e.dataTransfer.files[0];
    
        if (file.size > 5e06) {
            this.displayError("File is too big. Please upload under 5MB");
            return;
        }else if (this.props.documentConfig.allowedFileExts.split(",").find( f => f.replace(".","") == file.name.split(".").slice(-1).pop()) == undefined) {
            this.displayError("Wrong File Type Allowed Files are " + this.props.documentConfig.allowedFileExts);
            return;
        }
        let body = {
            projectId: this.props.projectId,
            documentConfigurationId: this.props.documentConfig.id,
            file: file,
        }
    
        if (this.state.uploadedId != NOTUPLOADED) body.id = this.state.uploadedId;
        let myFormData = new FormData();
        myFormData.append("file",body.file);
        myFormData.append("projectId",body.projectId);
        myFormData.append("documentConfiguationId",body.documentConfigurationId);
        myFormData.append("id",body.id);
        this.setState({ status: UPLOADING, progress: 0,file: {name: body.file.name, size:body.file.size/1e06} }, () => {
            DataService.uploadDocument(myFormData, this.setProgress)
                .then(res => {
                    if (res.data.statusCode == 200){
                     this.props.setDocument(res.data.response,this.props.id);
                     this.setState({ status: SELECTED, file: { name: res.data.response.name, size: res.data.response.fileSizeInMbs }, uploadedId: res.data.response.id }) 
                        
                    }
                     else 
                        throw "Server Reject File. Make sure to upload correct format";
                    })
                .catch(err => {
                    let file = {
                    };
                    let status = NOFILE;
                    let uploadedId = NOTUPLOADED;
                    if (this.props.document != undefined) {
                        file.size = this.props.document.fileSizeInMbs;
                        file.name = this.props.document.name;
                        uploadedId = this.props.document.id;
                        status = SELECTED;
                    }


                    this.setState({
                        file: file,
                        status: status,
                        progress: 0,
                        uploadedId: uploadedId,
                    });
                    toast.error(`An error occured: ${err.toString()}`);
                });
        });
    }
    setProgress = progressEvent => {
        let ratio = progressEvent.loaded / progressEvent.total;
        let percent = ratio * 100;

        this.setState({ progress: percent.toPrecision(3),file: {...this.state.file,size: ((this.state.file.size - (ratio*this.state.file.size))).toPrecision(3)} });

        console.log(percent.toPrecision(3));
    }
    startUpload = () => {
        //Begin Uploading File and tracking state
    }
    calculateFileSizeString(bytes) {
        if (bytes > 1e06) {
            return (bytes / 1e06).toPrecision(4) + ' MB';
        } else if (bytes > 1e03) {
            return (bytes / 1e03).toPrecision(4) + " KB";
        }
        return bytes + " Bytes"
    }
    render() {
        var selectedJSX = <div className="selected">
            <div className="left">
                {this.state.status === UPLOADING ? <span className="progressTop">
                    <span>{this.state.progress}%</span>
                    <ProgressBar progress={this.state.progress} />
                </span>
                    :

                    <span className="top">{this.state.fileError == ""?"Upload Complete":this.state.fileError}</span>}
            </div>
            {this.state.status === SELECTED ? [<label className='uploadbtn'  for={'myfile'+this.props.id}>Change File</label>,                <input type='file' onChange={this.uploadFile} id={'myfile' + this.props.id} />
] : ''}

        </div>
        return (
            <div className="FLMain2" id={this.props.id}>
                
                <img src={this.state.selected ? fileIcon : fileUploadIcon} width='30px' />

                {this.state.status !== NOFILE ? selectedJSX : <label id={`myfile${this.props.id}`} for={"myfile" + this.props.id}>{this.state.fileError != ""?this.state.fileError:"Drag file or click to upload"}</label>}
            </div>
        );
    }
}
