import React from 'react';
import InputField from "../../Components/InputField/InputField";
import Button from "../../Components/Button/Button";
//import './ResetPassword.css';
import { Redirect } from 'react-router-dom';
import DataService from '../../DataService/DataService';
import Loader from '../../Components/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';

export default class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errPass: false,
            errConfirmPass: false,
            labelConfirmPass: '',
            password: '',
            confirmPassword: '',
            token: '',
            email: '',
            loading: false,
        }
    }
    passwordRegex = /^(((?=.*\d)(?=.*[a-z])(?=.*[A-Z])){3}|((?=.*\d)(?=.*[a-z])(?=.*[!"#$%&'()*+, \-./:;<=>?@ [\\\]^_`}|}~])){3}|((?=.*\d)(?=.*[a-z])(?=.*[\u0080-\uffff])){3}|((?=.*\d)(?=.*[A-Z])(?=.*[!"#$%&'()*+, \-./:;<=>?@ [\\\]^_`}|}~])){3}|((?=.*\d)(?=.*[A-Z])(?=.*[\u0080-\uffff])){3}|((?=.*\d)(?=.*[!"#$%&'()*+, \-./:;<=>?@ [\\\]^_`}|}~])(?=.*[\u0080-\uffff])){3}|((?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+, \-./:;<=>?@ [\\\]^_`}|}~])){3}|((?=.*[a-z])(?=.*[A-Z])(?=.*[\u0080-\uffff])){3}|((?=.*[a-z])(?=.*[!"#$%&'()*+, \-./:;<=>?@ [\\\]^_`}|}~])(?=.*[\u0080-\uffff])){3}|((?=.*[A-Z])(?=.*[!"#$%&'()*+, \-./:;<=>?@ [\\\]^_`}|}~])(?=.*[\u0080-\uffff])){3}).{8,}$/g
    onFieldChange = e => {

        let newObj = {};
        newObj[e.target.id] = e.target.value;
        newObj.valid = true;
        this.setState(newObj, () => {
            if (this.state.password.match(this.passwordRegex) == null) {
                this.setState({ valid: false, passwordErr: "Password should be atleast of 8 characters and should contain atleast 1 numeric value and one symbol" });
            } else {
                this.setState({ passwordErr: "" });
            }

            if (this.state.confirmPassword != this.state.password) {
                this.setState({ valid: false, confirmPasswordErr: "Passwords dont match" });
            } else {
                this.setState({confirmPasswordErr: ""});
            }
        });
    }
    componentDidMount() {
        if (!this.props.newUser) {
            this.setState({ loading: true });
            try {
                let tokenRegex = /(\/token\/)([^\/]*)(\/)/;
                let emailRegex = /(\/email\/)([^\/]*)/;
                let token = this.props.location.pathname.match(tokenRegex)[2];
                let email = this.props.location.pathname.match(emailRegex)[2];
                debugger;
                console.log(token + " " + email);
                token = decodeURIComponent(token);
                email = decodeURIComponent(email);
                this.setState({ token: token, email: email });
                DataService.verifyPasswordToken(encodeURIComponent(token), encodeURIComponent(email))
                    .then(res => {
                        if (res.data.response == false) {
                            this.setState({ redirect: true, redirectURL: "/forget-password/tokenfail" });
                        } else {
                            this.setState({ loading: false });
                        }

                    }).catch(err => {
                        this.setState({ redirect: true, redirectURL: "/forget-password/tokenfail" });
                    })
            } catch (exception) {
                console.log(exception);
                this.setState({ redirect: true, redirectURL: '/forget-password' });
            }
        }
    }
    resetPassword = e => {
        e.preventDefault();

        this.setState({ errConfirmPass: false, errPass: false });
        let valid = this.state.valid;
        let updateObj = {};
        if (this.state.password == '') {
            updateObj.errPass = true;
            valid = false;
        }
        if (this.state.confirmPassword == '') {
            updateObj.errConfirmPass = true;
            valid = false;
            this.setState({ labelConfirmPass: "Please confirm password" });
        } else if (this.state.confirmPassword != this.state.password) {
            updateObj.errConfirmPass = true;
            valid = false;
            this.setState({ labelConfirmPass: "Passwords don't match" });
        }
        this.setState(updateObj);
        if (!valid) return;
        this.setState({ loading: true });
        let payload = {
            email: this.state.email?this.state.email:this.props.email,
            newPassword: this.state.password,
            token: this.state.token
        }
        debugger;
        if (this.props.newUser) {
            delete payload.token;
            DataService.resetPasswordForce(payload, this.props.token)
                .then(res => {
                    if (res.data.errors.length == 0) {
                        this.props.login(this.state.email,this.state.password);
                    } else {
                        this.props.clearUser(true);
                        toast.error("An unknown error just occurred");
                        this.setState({ loading: false });

                    }
                });
        } else {
            DataService.resetPassword(payload)
                .then(res => {
                    if (res.data.errors.length == 0) {
                        localStorage.setItem("newPassword","1");
                        this.setState({ redirect: true, redirectURL: '/login/resetsuccess' });
                    } else {
                        localStorage.setItem("newPassword","0");
                        this.setState({ redirect: true, redirectURL: '/forgotpass/tokenfail' });
                    }
                    this.setState({ loading: false });

                })
        }

    }
    render() {
        if (this.state.redirect) {
            window.location.href = window.location.origin + this.state.redirectURL
        }
        if (this.state.loading) return <Loader />
        return (
            <div id="reset-screen" class="login-wrapper">
                <main className="reset-heading">
                    <h2>Reset Password</h2>
                    {this.props.newUser ? <p>A temporary password may only be used once and please change to a new password to access your account.</p> : ""}
                    <form className="main-content login-form inner-wrapper" onSubmit={this.resetPassword}>
                        <div className="form-group">
                            <label for="newpassword">New Password</label>
                            <input type={this.state.showPassword?"text":"password"} className="form-control" onChange={this.onFieldChange} id="password" aria-describedby="emailHelp"
                                placeholder="New Password" required />
                            <a><i  onClick={() => {this.setState({showPassword: !this.state.showPassword})}} class={"fa fa-eye" + (this.state.showPassword?"":"-slash")} aria-hidden="true"></i></a>

                            <span style={{ color: "red", fontSize: "10px", textAlign : "left" }}>{this.state.passwordErr}</span>
                        </div>
                        <div className="form-group">
                            <label for="confirmpassword">Confirm Password</label>
                            <input type={this.state.showConfirmPassword?"text":"password"} required className="form-control" onChange={this.onFieldChange} id="confirmPassword" placeholder="Confirm Password" />
                            <a><i  onClick={() => {this.setState({showConfirmPassword: !this.state.showConfirmPassword})}} class={"fa fa-eye" + (this.state.showConfirmPassword?"":"-slash")} aria-hidden="true"></i></a>
                            <span style={{ color: "red", fontSize: "10px", textAlign : "left" }}>{this.state.confirmPasswordErr}</span>


                        </div>
                        <button type="submit" className="btn btn-primary">Reset Password</button>
                    </form>
                </main>
            </div>
        );
    }
}


