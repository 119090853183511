import React, { Component } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
// import './Index.css';
import Login from '../../ViewComponents/Login/Login';
import welcomeImg from "../../images/welcome-icon.png"

import {
    HashRouter,
    Router,
    withRouter,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { createBrowserHistory } from 'history'
import ForgotPassword from '../../ViewComponents/ForgotPassword/ForgotPassword';
import ResetPassword from '../../ViewComponents/ResetPassword/ResetPassword';
import { ToastContainer, toast } from 'react-toastify';
import StartTokenizationProcess from '../../ViewComponents/StartTokenizationProcess/StartTokenizationProcess';
import Dashboard from '../Dashboard/Dashboard';
import DataService from '../../DataService/DataService';
// import RegisterView from '../../ViewComponents/RegisterView/RegisterView';
import DocumentVerificationScreen from '../../ViewComponents/DocumentVerificationScreen/DocumentVerficationScreen';
import Loader from '../../Components/Loader/Loader';
import '../../Assets/css/main.css';
import MyProjects from '../../ViewComponents/MyProjects/MyProjects';
import MyProjectDetail from '../../ViewComponents/MyProjectDetail/MyProjectDetail';
import MyProfile from '../../ViewComponents/MyProfile/MyProfile';
import WalletBalances from '../../ViewComponents/WalletBalances/WalletBalances';
import SecurityManagment from '../../ViewComponents/SecurityManagment/SecurityManagment';
import AdminPanel from '../../ViewComponents/AdminPanel/AdminPanel';
import Button from '../../Components/Button/Button';
import Withdraw from '../../ViewComponents/Withdraw/Withdraw';
import Sidebar from '../../Components/Sidebar/Sidebar';
import ReactTooltip from 'react-tooltip';
import Overview from '../../ViewComponents/Overview/Overview';
import Security from '../../ViewComponents/Security/Security';
import Support from '../../ViewComponents/Support/Support';
import VerifyWithdraw from '../../ViewComponents/VerifyWithdraw/VerifyWithdraw';
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            loading: true,
            active: false,
            refresh: true,
        }
        this.history =  createBrowserHistory();


    }

    toggleActive = () => {
        this.setState({ active: !this.state.active })
    };
    forceRefresh = () => {
        console.log("Refresh");
        this.setState({refresh:!this.state.refresh});
    }
    clearUser = error => {
        if (!error) {
            localStorage.setItem("token", this.state.user.accessToken);
            window.location.href = "/";
            return;
        }
        this.setState({ user: {}, authenticate: false, newUser: false, newLogin: !error });
        if (!error) window.location.href = "/first-login";
    };
    forceLogin = (email, password) => {
        debugger;
        DataService.doLogin({ email: this.state.user.email, password: password })
            .then(res => res).then(res => {
                this.setState({ user: res.data.response, loading: false, authenticate: true }, () => { debugger; localStorage.setItem("token", this.state.user.accessToken);localStorage.setItem("firstLogin","1"); window.location.href = "/document-verification" });
            })
            .catch(err => {
                this.setState({ authenticate: false, loading: false })
            })
    }
    reLogin = (password) => {
        DataService.doLogin({ email: this.state.user.email, password: password })
        .then(res => res).then(res => {
            localStorage.setItem("token",res.data.response.accessToken);
            this.updateUser();
        })
        .catch(err => {
            this.setState({ authenticate: false, loading: false })
        })
    }
    updateUser = () => {
        DataService.getUser()
            .then(res => {
                this.setState({ user: res.data.response, loading: false, authenticate: true })
            })
            .catch(err => {
                this.setState({ authenticate: false, loading: false })
            })
    }
    showWelcomeModal = () => this.setState({newLogin: true});

    componentDidMount() {
        this.history.listen(() => {
            // location is an object like window.location
            this.forceUpdate();
            console.log("Hello");
        });
        window.onhashchange = () => {
            console.log("listen");
        }
        DataService.getUser()
            .then(res => {
                this.setState({ user: res.data.response, loading: false, authenticate: true, newLogin: localStorage.getItem("firstLogin") == "1" })
            })
            .catch(err => {
                this.setState({ authenticate: false, loading: false })
            })
            
    }
    componentDidUpdate() {
    }
    setUser = user => this.setState(user);
    render() {
        if (this.state.loading) return <Loader />
       
        let withoutSidebar = window.location.href.match(/document-verification|tokenize|support|withdraw/) != null ||  !this.state.authenticate || this.state.newUser;
        let authenticatedJSX = [<Route path="/document-verification" component={DocumentVerificationScreen} />,
        <Route path="/tokenize" component={StartTokenizationProcess} />,

        <Route path="/admin" component={AdminPanel} />,
        <Route path="/wallet/transfer" render={props => < Withdraw email={this.state.user.email} faEnabled = {(this.state.user.isGAuthActive == 1 || this.state.user.isMobileVerified == 1)} {...props} />} />,
        <Route path="/withdraw/token/:token" render={props => < VerifyWithdraw email={this.state.user.email} faEnabled = {this.state.user.isGAuthActive == 1 || this.state.user.isMobileVerifed == 1} {...props} />} />,
        <Route path="/withdraw" render={props => < VerifyWithdraw email={this.state.user.email} faEnabled = {this.state.user.isGAuthActive == 1 || this.state.user.isMobileVerifed == 1} {...props} />} />,

        <Route path='/wallet' render={props => <WalletBalances {...props} />} />,
        <Route path='/myprofile' render={(props) => <MyProfile forceRefresh={this.forceRefresh} {...props} setUser={this.setUser} user={this.state.user} />} />,
        <Route path='/myprojects/detail' render={props => <MyProjectDetail {...props}/>} />,
        <Route path='/myprojects' render ={props => <MyProjects {...props}/>} />,
        this.state.user.isGAuthActive==1?"":<Route path="/security/gauth" render={props => <Security updateUser={this.updateUser} {...props}/>} />,
        <Route path='/security' render={(props) => <SecurityManagment user={this.state.user} login={this.reLogin} updateUser={this.updateUser} {...props} />} />,
        <Route path='/support' component={Support}/>,
        <Route path="/" render={props => <Overview  faEnabled = {(this.state.user.isGAuthActive == 1 || this.state.user.isMobileVerified == 1)} modal={this.state.newLogin  } showWelcomeModal={this.showWelcomeModal} {...props}/>} />
        ];
        let publicJSX = [
            // <HashRouter>

            // </HashRouter>
            <Route path="/forget-password" render={props => <ForgotPassword {...props} />} />,
            <Route path="/reset-password" render={props => <ResetPassword {...props} email={this.state.user.email}/>} />,
            <Route path="/" render={props => <Login {...props} setUser={this.setUser} />} />,

        ];
        if (this.state.newUser) {
            authenticatedJSX = <Route path="/" render={props => <ResetPassword {...props} email={this.state.user.email} clearUser={this.clearUser} login={this.forceLogin} newUser={true} token={this.state.user.accessToken} />} />
        } 
        
    
        let switchJSX = <Switch>
            {this.state.authenticate ? authenticatedJSX : publicJSX}

        </Switch>;
        return (
            <Router history={this.history}>
                <div>
                    <Navbar noSidebar={withoutSidebar} authenticated={this.state.authenticate} toggleSidebar={this.toggleActive} />
                    {this.state.loading? <Loader/> : (withoutSidebar? switchJSX :
                    [<div className="wrapper">
                        <Sidebar location={this.state.refresh} active={this.state.active} updateSelection={this.state.updateSelection} clearSelection={this.clearSelection} collapse={this.state.collapse} user={this.state.user} /> 
                        {switchJSX}
                    </div>,
                    
                  ])}

                  <div className={"modal fade"} id="welcomeModal" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header text-center">
                      <button type="button" id="welcomeModalClose" className="close" data-dismiss="modal" aria-label="Close">
                        <span className="x-icon" aria-hidden="true">&times;</span></button>

                      <button type="button" id="welcomeModalOpen" className="close" data-target="#welcomeModal"
                        data-toggle="modal" aria-label="Close">
                        <span className="x-icon" hidden aria-hidden="true">&times;</span></button>
                      <img src={welcomeImg} alt="Welcome to Fasset Dashboard" />
                      <h5 class="modal-title mt-20 mb-20" id="exampleModalLongTitle">Welcome to the Fasset Dashboard</h5>
                      <button type="button" onClick={() => { localStorage.removeItem("projectId");window.location.href = "/tokenize"; }} style={{ padding: "20px" }} class="btn btn-primary">Start Tokenization Process</button>
                      <span>Need Help? <a href="/support">Click here</a></span>
                    </div>
                    <div class="modal-footer">
                      <a class="float-right close" data-dismiss="modal" onClick={() => document.getElementById("welcomeModalClose").click()} aria-label="Close" href="#">Skip</a>
                    </div>
                  </div>
                </div>
              </div>
                </div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

            </Router>);
    }
    // let authenticatedJSX = [<Route path="/document-verification" component={DocumentVerificationScreen} />,

    // <Route path="/tokenize" component={StartTokenizationProcess} />,
    // <Route path="/" render={props => <Login {...props} setUser={this.setUser} user={this.state.user} />} />,
    // ];
    // let publicJSX = [
    // <Route path="/forget-password" component={ForgotPassword} />,
    // <Route path="/reset-password" component={ResetPassword} />,
    // <Route path="/" render={props => <Login {...props} setUser={this.setUser} />} /> 
    // ];
    // let switchJSX = <Switch>
    //     {this.state.authenticate ? authenticatedJSX : publicJSX }

    // </Switch>;
    // return (
    //     <Router>
    //         <div className="IndexMain">
    //             <div className="Content">
    //                 {this.state.loading ? <Loader /> : switchJSX}
    //             </div>

    //         </div>
    //     </Router>);
}


export default Index;