import React from 'react';
//import './Select.css';

export default class Select extends React.Component {
    render() {
        return (
            <div className="SelectMain">
                <label>
                    {this.props.label}
                </label>
                <select  disabled={this.props.disabled? "disabled":""} id={this.props.id} value={this.props.value} placeholder={this.props.placeholder} onChange={this.props.onChange}>
                    {this.props.options.map(o => 
                    <option value={o.val}>{o.text}</option>)}
                </select>
                <label>
                    {this.props.err?this.props.errLabel:''}
                </label>
            </div>
        );

    }
}