import React, { Component } from 'react';
import './MyProjects.css';
import ProjectStatusList from '../../Components/ProjectStatusList/ProjectStatusList';
import SideProjectList from '../../Components/SideProjectList/SideProjectList';
import Button from '../../Components/Button/Button';
import DataService from '../../DataService/DataService';
import { Redirect } from 'react-router';
import Loader from '../../Components/Loader/Loader';
import noProjectsIcon from '../../Assets/images/no-projects.svg';
import ProgressBar from '../../Components/ProgressBar/ProgressBar';
class MyProjects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: [],
      sectors: [],
      loading: true,
    }
  }
  projectStyle = {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    'min-height': '250px',
    justifyContent: 'center',
    alignItems: 'center'

  }
  componentDidMount() {

    Promise.all([
      DataService.getProjects(),
      DataService.getSectors()
    ]).then(res => this.setState({ loading: false, sectors: res[1].data.response.data, projects: res[0].data.response.data }));

  }
  render() {
    if (this.state.loading) return <Loader />
    if (this.state.redirect) return <Redirect to={this.state.redirectUrl} />
    let noIncompleteProjectsJSX = <div style={this.projectStyle} className="col">
      <i class="fa fa-check-circle" style={{ fontSize: "60px", color: "#008787", marginTop: "10px", marginBottom: "15px" }} />
      <span style={{ textAlign:"center", fontSize: "20px", fontWeight: '500' }}>All Projects Completed</span>
      <span style={{textAlign:"center"}}>There are no projects left to complete good job!</span>
    </div>
    let filteredProjects = this.state.projects.filter(project => project.lastStepCompleted < 3);
    let myProjectsJSX = <div className="row">
      <div className="col-md-12 col-lg-8">
        {this.state.projects.map(project =>
          <div className={"inner-padding  " + (this.state.overlay == -1 ? "button-overlay" : "")} onMouseEnter={() => this.setState({ overlay: project.id })} onMouseOut={() => this.setState({ overlay: undefined })}>
            <span className="dept" style={{borderColor: "#0099a1",color:"#0099a1"}}>{project.sector.name}</span>
            <div className="project-disc" style={{ "margin-bottom": "0px", "border-bottom": "0px" }}>
              <div className="project-status">
                <div className="heading-wrapper">
                  <h5>{project.name}</h5>
                  {project.status.name == "InProgress"?"":<small>{project.status.name.toLowerCase() == "completed"?"Awaiting Tokenization":"Token Minted"}</small>}
                  <p style={{marginTop:"-10px"}}>{project.symbol}<span>{project.id}</span></p>
                  {/* <span>Awaiting Tokenization</span> */}
                </div>
                <span style={{padding:"5px 15px",...(project.status.name.toLowerCase() === "inprogress"?{color:"white"}:{backgroundColor:"#A4DE02",color:"white"})}} className={project.status.name.toLowerCase() === "inprogress" ? "inprocess" : "complete"}>{project.status.name}</span>

              </div>
            </div>
            <button type="button" onClick={() => this.props.history.push(`/myprojects/detail/${project.id}`)} className="btn btn-primary d-none">View Project</button>

          </div>
        )}


      </div>


      <div className="col-md-12 col-lg-4">
        <div className="button-wrapper w-100 mt-0">
          <button type="button" onClick={() => { localStorage.removeItem("projectId"); window.location.href="/tokenize"; }}
            className=" btn-token btn btn-primary w-100 tokenize-btn" style={{"padding":" 14px 0px",fontSize:"13px !important"}}>Start Tokenization</button>
        </div>
        <div className="inner-padding content-right">
          <h5>Complete Your Tokenization Process</h5>
          {filteredProjects.length == 0?noIncompleteProjectsJSX:
          filteredProjects.map(project =>
            <div className="content-job">
              <div className="heading-wrapper">
                <strong>{project.name}</strong>
                <p>{project.symbol}</p>
                <span className="text-left">{project.id}</span>
                <div className="upload-bar upload-progress">
                  <span className="text-left mt-2">{((project.lastStepCompleted / 3) * 100).toPrecision(2)}% completed</span>
                  <div className="progress">
                    <ProgressBar progress={((project.lastStepCompleted / 3) * 100).toPrecision(2)} />
                  </div>
                </div>
              </div>
              <button type="button" onClick={() => { window.location.href="/tokenize";; localStorage.setItem("projectId", project.id); }} className="float-right btn btn-primary">Continue</button>
            </div>
          )
          }

        </div>
      </div>
    </div>
    let noProjectsJSX = <div style={this.projectStyle} className="col">
      <img src={noProjectsIcon} style={{ marginTop: "30px" }} width="200px" />
      <span style={{ fontSize: "20px", fontWeight: '500' }}>No projects</span>
      <span>Looks like we have no projects. Lets tokenize one!</span>
      <div className=" button-wrapper w-80 " style={{ marginTop: "40px", marginBottom: "40px" }}>
        <button type="button" onClick={() => { localStorage.removeItem("projectId"); window.location.href="/tokenize"; }} style={{ "padding": "10px 25px" }}
          className=" btn btn-primary w-80">Start Tokenization</button>
      </div>
    </div>

    return (
      <div id="my-projects" className="my-projects status-detail" style={{ "width": "100%" }}>
        <div className="center-content  project-wrapper" >

          <div className="project-cards">
            <h4 className="mb-20">My Projects</h4>
            {this.state.projects.length == 0 ? noProjectsJSX : myProjectsJSX}
          </div>

        </div>
      </div>
    );
  }
}

export default MyProjects;