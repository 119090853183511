import React from 'react';
import './StartTokenizationProcess.css';
import FileUploader from '../../Components/FileUploader/FileUploader';
import DataService from "../../DataService/DataService";
import Loader from '../../Components/Loader/Loader';


export default class StartTokenizationProcess extends React.Component {
    constructor() {
        super();
        this.state = {
            months: [{ id: 1, name: 'Jan' },
            { id: 2, name: 'Feb' },
            { id: 3, name: 'Mar' },
            { id: 4, name: 'Apr' },
            { id: 5, name: 'May' },
            { id: 6, name: 'Jun' },
            { id: 7, name: 'Jul' },
            { id: 8, name: 'Aug' },
            { id: 9, name: 'Sep' },
            { id: 10, name: 'Oct' },
            { id: 11, name: 'Nov' },
            { id: 12, name: 'Dec' }],
            isIssueSizeGreater: false,
            isProjectSizeEntered: true,
            disableControlsWhileUploadingDoc: false,
            docLength: [],
            requiredDocLength: [],
            screenMode: 'add',
            message: null,
            validations: {
                "sectorId": null,
                "name": null,
                "symbol": null,
                "projectSize": null,
                "issueSize": null,
                "concessionMonth": null,
                "concessionYear": null,
                "aggregateAnnualizedReturnPercent": null
            },
            documentCount: 0, uploadCount: 0, sectors: [], isDataLoaded: false, loadProjectConfig: [], projectUploadedDocuments: [], project: {
                "sectorId": null,
                "userId": null,
                "name": null,
                "shortName": null,
                "symbol": null,
                "projectSize": null,
                "issueSize": null,
                "concessionMonth": null,
                "concessionYear": null,
                "aggregateAnnualizedReturnPercent": null,
                "description": "project details",
                "isAgreedOnServiceAgreement": false,
                "lastStepCompleted": 1
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        var value = localStorage.getItem("projectId");
        if (value) {
            this.state.screenMode = "edit";
            this.loadProjectByProjectId(value);
            this.loadDocuments(value);
        }
        else {
            this.loadProjectConfig(null);
            this.loadUserId();
        }

        this.loadAllSectors();
        this.afterDocumentUploaded = this.afterDocumentUploaded.bind(this);
        this.fileUploadHandler = this.fileUploadHandler.bind(this);
    }

    nextTab() {
        var ul = document.getElementById('progressbar');
        if (ul) {
            var tabs = ul.getElementsByClassName('step0 inprogress');
            if (tabs[0].id === 'tab1') {
                if (!this.isProjectDetailsProvided()) {
                    return false;
                }
                var tab2 = document.getElementById('tab2');
                tab2.className = "step0 inprogress";
                var step1 = document.getElementById('step1');
                step1.className = "tab-pane";
                var step2 = document.getElementById('step2');
                step2.className = "tab-pane active";
                this.onClickSaveButton(1, "proceed");
            }
            if (tabs[0].id === 'tab2') {
                if (!this.isAllDocumentsUploaded()) {
                    alert('Please upload required documents to proceed.');
                    return false;
                }
                var tab3 = document.getElementById('tab3');
                tab3.className = "step0 inprogress";
                var step2 = document.getElementById('step2');
                step2.className = "tab-pane";
                var step3 = document.getElementById('step3');
                step3.className = "tab-pane active";
                this.onClickSaveButton(2, "proceed");
            }
            if (tabs[0].id === 'tab3') {
                var tab4 = document.getElementById('tab4');
                tab4.className = "step0 inprogress";
                var step3 = document.getElementById('step3');
                step3.className = "tab-pane";
                var complete = document.getElementById('complete');
                complete.className = "tab-pane active";
                this.onClickSaveButton(3, "proceed")
            }
            tabs[0].className = "step0 active";
        }
    }

    prevTab() {
        var ul = document.getElementById('progressbar');
        if (ul) {
            var tabs = ul.getElementsByClassName('step0 inprogress');
            if (tabs[0].id === 'tab3') {
                tabs[0].className = "step0";
                var tab2 = document.getElementById('tab2');
                tab2.className = "step0 inprogress";
                var step3 = document.getElementById('step3');
                step3.className = "tab-pane";
                var step2 = document.getElementById('step2');
                step2.className = "tab-pane active";
            }
            else if (tabs[0].id === 'tab2') {
                tabs[0].className = "step0";
                var tab1 = document.getElementById('tab1');
                tab1.className = "step0 inprogress";
                var step2 = document.getElementById('step2');
                step2.className = "tab-pane";
                var step1 = document.getElementById('step1');
                step1.className = "tab-pane active";
            }
        }
    }

    loadAllSectors() {
        DataService.getAllSectors().then(res => {
            this.setState({ sectors: res.data.response.data, isDataLoaded: true });
            document.getElementsByTagName("body")[0].style.background = "white";
            document.getElementById("root").style.background = "white";
        });
    }

    loadProjectConfig(projectId) {
        if (projectId) {
            DataService.getDocumentsConfigList().then(res => {
                this.setState({ loadProjectConfig: res.data.response });
                DataService.getProjectUploadedDocuments(projectId).then(res => {
                    for (let i = 0; i < this.state.loadProjectConfig.length; i++) {
                        for (let j = 0; j < res.data.response.length; j++) {
                            if (this.state.loadProjectConfig[i].id === res.data.response[j].documentConfigurationId) {
                                this.state.loadProjectConfig[i].fileSizeInMbs = Math.round(res.data.response[j].fileSizeInMbs);
                                this.state.loadProjectConfig[i].documentId = res.data.response[j].id;
                                this.setState(this.state);
                            }
                        }
                    }
                });
            });
        }
        else {
            DataService.getDocumentsConfigList().then(res => {
                this.setState({ loadProjectConfig: res.data.response });
                this.setState(this.state);
            });
        }
    }

    onClickSaveButton(step_no, type) {
        if (this.state.project.lastStepCompleted > 2 && step_no === 4) {
            this.setState({ isDataLoaded: false });
            this.setState({ message: "Please wait, we're saving your project details..." });
            window.location.href = "/dashboard"
            return false;
        }
        if (!this.isProjectDetailsProvided()) {
            return false;
        }
        if (type === "saveAndSkip") {
            this.setState({ isDataLoaded: false });
            this.setState({ message: "Please wait, we're saving your project details..." });
        }
        if (step_no === 1) {
            var shortName = this.state.project.name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '');
            this.state.project.shortName = shortName.toUpperCase();
            this.state.project.symbol += ".FIT";
            this.state.project.isAgreedOnServiceAgreement = this.state.project.isAgreedOnServiceAgreement ? 1 : 0;
            this.state.project.projectSize = this.removeCommaAndParseFloat(this.state.project.projectSize);
            this.state.project.issueSize = this.removeCommaAndParseFloat(this.state.project.issueSize);
            if (this.state.project.id === null || this.state.project.id === undefined) {
                if (type === "proceed") this.setState({ message: 'Please wait, we’re getting things ready.' }); // DONOT MODIFY THIS LINE WITHOUT ASKING
                DataService.createProject(this.state.project).then(res => {
                    if (type === "saveAndSkip") {
                        window.location.href = "/myprojects"
                    }
                    else {
                        this.state.project.id = res.data.response.id;
                        this.loadDocuments(this.state.project.id);
                    }
                }).catch((err) => {
                    alert(err);
                    this.setState({ isDataLoaded: true });
                })
            }
            else {
                if (type === "proceed") this.setState({ message: 'Please wait, we’re getting things ready.' }); // DONOT MODIFY THIS LINE WITHOUT ASKING
                DataService.updateProject(this.state.project).then(res => {
                    if (type === "saveAndSkip") {
                        window.location.href = "/myprojects"
                    }
                    else {
                        this.state.project.id = res.data.response.id;
                        this.loadDocuments(this.state.project.id);
                    }
                }).catch((err) => {
                    alert(err);
                    this.setState({ isDataLoaded: true });
                })
            }

        }
        else if (step_no === 2 || step_no === 3) {
            if (this.isAllDocumentsUploaded() && step_no === 2 && !this.state.project.isAgreedOnServiceAgreement) {
                this.state.project.lastStepCompleted = step_no;
            }
            if (step_no === 3) {
                if (this.state.project.isAgreedOnServiceAgreement) this.state.project.lastStepCompleted = step_no;
                else if (!this.state.project.isAgreedOnServiceAgreement) this.state.project.lastStepCompleted = 2;
            }
            this.state.project.isAgreedOnServiceAgreement = this.state.project.isAgreedOnServiceAgreement ? 1 : 0;
            DataService.updateProject(this.state.project).then(res => {
                if (type === "saveAndSkip") {
                    window.location.href = "/myprojects"
                }
            }).catch((err) => {
                alert(err);
                this.setState({ isDataLoaded: true });
            })
        }
    }

    isProjectDetailsProvided() {
        var result = false;
        if (this.state.project.name && this.state.project.symbol && this.state.project.sectorId && this.state.project.projectSize && this.state.project.issueSize
            && this.state.project.concessionMonth && this.state.project.concessionYear && this.state.project.aggregateAnnualizedReturnPercent) {
            result = true;
        }
        this.showValidationLabels();
        return result;
    }

    showValidationLabels() {
        this.setState({
            validations: {
                ...this.state.validations,
                name: this.state.project.name ? true : false,
                symbol: this.state.project.symbol ? true : false,
                issueSize: this.state.project.issueSize ? true : false,
                projectSize: this.state.project.projectSize ? true : false,
                concessionMonth: this.state.project.concessionMonth ? true : false,
                concessionYear: this.state.project.concessionYear ? true : false,
                sectorId: this.state.project.sectorId ? true : false,
                aggregateAnnualizedReturnPercent: this.state.project.aggregateAnnualizedReturnPercent ? true : false
            }
        });
    }

    isAllDocumentsUploaded() {
        let docs = [...new Set(this.state.docLength)];
        let reqDocs = [...new Set(this.state.requiredDocLength)];
        return (docs.length === reqDocs.length);
    }

    loadProjectByProjectId(projectId) {
        DataService.getProjectByProjectId(projectId).then(res => {
            this.state.project.id = res.data.response.id;
            this.state.project.sectorId = res.data.response.sector.id;
            this.state.project.userId = res.data.response.userId;
            this.state.project.name = res.data.response.name;
            this.state.project.shortName = res.data.response.shortName;
            this.state.project.symbol = res.data.response.symbol.replace(".FIT", "");
            this.state.project.projectSize = res.data.response.projectSize;
            this.state.project.issueSize = res.data.response.issueSize;
            this.state.project.concessionMonth = res.data.response.concessionMonth;
            this.state.project.concessionYear = res.data.response.concessionYear;
            this.state.project.aggregateAnnualizedReturnPercent = res.data.response.aggregateAnnualizedReturnPercent;
            this.state.project.description = res.data.response.description;
            this.state.project.lastStepCompleted = res.data.response.lastStepCompleted;
            this.state.project.isAgreedOnServiceAgreement = res.data.response.isAgreedOnServiceAgreement === 1 ? true : false;
            this.setState({ isDataLoaded: true });
        });
    }

    updateValues(fieldName, e) {
        this.setState({
            project: {
                ...this.state.project,
                [fieldName]: (fieldName === 'projectSize' || fieldName === 'issueSize') ? this.commaSeparated(e.target.value.replace(/[^0-9.]/g, "")) : e.target.value
            }
        })
    }

    updateToThreeDecimalDigits(fieldName, e) {
        if (!e.target.value) return false;
        this.setState({
            project: {
                ...this.state.project,
                [fieldName]: parseFloat(e.target.value.replace(/[^0-9.]/g, "")) % 1 === 0 ? e.target.value : this.commaSeparated(parseFloat(e.target.value.replace(/[^0-9.]/g, "")).toFixed(e.target.value.split(".")[1].length > 18 ? 18 : e.target.value.split(".")[1].length))
            }
        })
    }

    handleChange(event) {
        this.setState({
            project: {
                ...this.state.project,
                isAgreedOnServiceAgreement: event.target.checked
            }
        })
    }

    loadDocuments(projectId) {
        Promise.all(
            [DataService.getDocumentsConfigList(),
            DataService.getProjectDocuments(projectId)
            ]).then(responses => {
                for (var i = 0; i < responses[0].data.response.length; i++) {
                    if (responses[0].data.response[i].isRequired === 1) this.state.requiredDocLength.push(responses[0].data.response[i].id);
                }
                for (var i = 0; i < responses[1].data.response.length; i++) {
                    if (responses[1].data.response[i].documentConfiguration.isRequired === 1) this.state.docLength.push(responses[1].data.response[i].id);
                }
                this.setState({ loadProjectConfig: responses[0].data.response, projectUploadedDocuments: responses[1].data.response, isDataLoaded: true, message: '' });
            });
    }

    afterDocumentUploaded = (id) => {
        this.state.docLength.push(id);
    }

    fileUploadHandler(result) {
        this.setState({ disableControlsWhileUploadingDoc: result });
    }

    handleBlur(e) {
        if (e.target.value) document.getElementById('isSymbolAvailable').innerText = "";
        if (e.target.value && this.state.screenMode === "add") {
            DataService.checkIfProjectSymbolAvailable(e.target.value + ".FIT", null).then(res => {
                if (!res.data.response) {
                    document.getElementById('isSymbolAvailable').innerText = "Symbol not available";
                }
            }).catch((err) => {
                alert(err);
            })
        }
        else if (e.target.value && this.state.screenMode === "edit") {
            DataService.checkIfProjectSymbolAvailable(e.target.value + ".FIT", this.state.project.id).then(res => {
                if (!res.data.response) {
                    document.getElementById('isSymbolAvailable').innerText = "Symbol not available";
                }
            }).catch((err) => {
                alert(err);
            })
        }
    }

    loadUserId() {
        DataService.getUser().then(res => {
            if (res.data.response) {
                this.state.project.userId = res.data.response.id;
            }
        }).catch((err) => {
            alert(err);
        })
    }

    commaSeparated(x) {
        if (x) {
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        }
    }

    removeCommaAndParseFloat(value) {
        return value ? parseFloat(value.toString().replace(/[^0-9.]/g, "")) : null;
    }

    changListCSS(id) {
     document.getElementById(id).className = "selectListAfter";
    }

    render() {

        if ((this.state.screenMode === "add" && this.state.isDataLoaded) || (this.state.screenMode === "edit" && this.state.project.id && this.state.isDataLoaded)) {
            let array = [];
            for (let i = 2020; i <= 2050; i++) array.push(i);
            return (

                <div className="wizard" style={{ backgroundColor: 'white', maxWidth: '850px', margin: '40px auto 20px' }}>
                    <div class="process-wrap" style={{ marginTop: '100px' }}>
                        <div class="card-title text-center">
                            <h2>Start Tokenization Process</h2>
                            {/* <!-- Snippet Status Bar --> */}
                            <div class="card">
                                {/* <!-- Add class 'active' to progress with tick mark --> */}
                                <div class="d-flex justify-content-center">
                                    <div class="col-12">
                                        <ul id="progressbar" class="text-center">
                                            <li class="step0 inprogress" id="tab1"><span>Project Details</span></li>
                                            <li class="step0" id="tab2"><span>Project Documentations</span></li>
                                            <li class="step0" id="tab3"><span>Service Agreement</span></li>
                                            <li class="step0" id="tab4"><span>Done</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Snippet Ends --> */}
                        </div>
                    </div>
                    <main class="main-content-data" style={{ marginTop: '-60px' }}>
                        <div class="inner-wrapper">
                            <div className="tab-content">
                                <div id="step1" className="tab-pane active" style={{ padding: '0px' }}>
                                    <div class="heading-wrapper">
                                        <h4>Project Details</h4>
                                    </div>
                                    <div class="form-wrapper">
                                        <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <label for="project">Project Name</label>
                                                <input class="form-control" onChange={e => { this.updateValues('name', e); this.state.validations.name = e.target.value ? true : false; }} value={this.state.project.name} disabled={this.state.project.lastStepCompleted > 3}
                                                    style={{ padding: '6px 16px', border: (this.state.validations.name === false ? '2px solid red' : '2px solid #b8b8b8') }} type="text" placeholder="Example: Abu Dhabi Solar Panel"></input>
                                                {this.state.validations.name === false ? <label style={{ color: 'red', fontSize: '13px' }}>Please provide project name</label> : ""}
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="sector">Symbol</label>
                                                <div class="input-group">
                                                    <input class="form-control" id="projectSymbol" onChange={e => { e.target.value = e.target.value.toUpperCase(); this.updateValues('symbol', e); this.state.validations.symbol = e.target.value ? true : false; }} onBlur={this.handleBlur} value={this.state.project.symbol} disabled={this.state.project.lastStepCompleted > 3}
                                                        style={{ padding: '6px 16px', border: (this.state.validations.symbol === false ? '2px solid red' : '2px solid #b8b8b8') }} type="text" placeholder="Example: ADSP"></input>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" style={{border: (this.state.validations.symbol === false ? '2px solid red' : '2px solid #b8b8b8'), marginLeft: '-2px'}}>
                                                            <label style={{marginTop: '2px', fontWeight: '500'}}>.FIT</label>
                                                        </span>
                                                    </div>
                                                </div>
                                                <i id="isSymbolAvailable" class="fa" style={{ fontFamily: 'sans-serif', fontSize: '13px', marginRight: '50px', marginTop: '2px' }}></i>
                                                {this.state.validations.symbol === false ? <label style={{ color: 'red', fontSize: '13px' }}>Please provide symbol</label> : ""}
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="sector">Sector</label>
                                                <select id="sector_id" class="selectListBefore" onChange={e => { this.updateValues('sectorId', e); this.state.validations.sectorId = e.target.value ? true : false; if(e.target.value) this.changListCSS("sector_id");}} value={this.state.project.sectorId} disabled={this.state.project.lastStepCompleted > 3}
                                                    style={{ border: (this.state.validations.sectorId === false ? '2px solid red' : '2px solid #b8b8b8') }}>
                                                    <option selected disabled>&nbsp; Choose Sector</option>
                                                    {
                                                        this.state.sectors.sort((a, b) => a.name > b.name ? 1 : -1).map((value, index) => {
                                                            return <option value={value.id}>&nbsp; {value.name}</option>
                                                        })
                                                    }
                                                    <option value="100">&nbsp; Others</option>
                                                </select>
                                                <i class="fa fa-angle-down" aria-hidden="true" style={{ color: '#b8b8b8', marginRight: '10px', pointerEvents: 'none' }}></i>
                                                {this.state.validations.sectorId === false ? <label style={{ color: 'red', fontSize: '13px' }}>Please select sector</label> : ""}
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="project-size">Project Size</label>
                                                <input class="form-control" onBlur={e => this.updateToThreeDecimalDigits('projectSize', e)} onChange={e => { if (this.removeCommaAndParseFloat(this.state.project.projectSize)) { this.setState({ isProjectSizeEntered: true }) }; e.target.value = e.target.value.replace(/[^0-9.]/g, ""); if (this.removeCommaAndParseFloat(e.target.value) < 0) e.target.value = ""; this.updateValues('projectSize', e); this.state.validations.projectSize = e.target.value ? true : false; }} value={this.commaSeparated(this.state.project.projectSize)} disabled={this.state.project.lastStepCompleted > 3}
                                                    style={{ padding: '6px 16px', border: (this.state.validations.projectSize === false ? '2px solid red' : '2px solid #b8b8b8') }} type="text" placeholder="Example: 200,000,000,000"></input>
                                                {this.state.validations.projectSize === false ? <label style={{ color: 'red', fontSize: '13px' }}>Please provide project Size</label> : ""}
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="issue-size">Issue Size</label>
                                                <input class="form-control" onBlur={e => this.updateToThreeDecimalDigits('issueSize', e)} onChange={e => { if (this.removeCommaAndParseFloat(this.state.project.projectSize)) { this.setState({ isProjectSizeEntered: true }) } else { this.setState({ isProjectSizeEntered: false }); e.target.value = ""; }; if (this.removeCommaAndParseFloat(this.state.project.projectSize) && (this.removeCommaAndParseFloat(e.target.value) > this.removeCommaAndParseFloat(this.state.project.projectSize))) { e.target.value = this.state.project.projectSize; this.setState({ isIssueSizeGreater: true }); } else this.setState({ isIssueSizeGreater: false }); e.target.value = e.target.value.replace(/[^0-9.]/g, ""); if (this.removeCommaAndParseFloat(e.target.value) < 0) e.target.value = ""; this.updateValues('issueSize', e); this.state.validations.issueSize = e.target.value ? true : false; }} value={this.commaSeparated(this.state.project.issueSize)} disabled={this.state.project.lastStepCompleted > 3}
                                                    style={{ padding: '6px 16px', border: (this.state.validations.issueSize === false ? '2px solid red' : '2px solid #b8b8b8') }} type="text" placeholder="Example: 30,000,000,000"></input>
                                                {/* {this.state.validations.issueSize === false ? <label style={{ color: 'red', fontSize: '13px' }}>Please provide issue Size</label> : ""} */}
                                                {!this.state.isProjectSizeEntered ? <label style={{ color: 'red', fontSize: '13px' }}>Please enter 'Project Size' first</label> : (
                                                    this.state.validations.issueSize === false ? <label style={{ color: 'red', fontSize: '13px' }}>Please provide issue Size</label> : ""
                                                )}
                                                {this.state.isIssueSizeGreater ? <label style={{ color: 'red', fontSize: '13px' }}>Should not be greater than 'Project Size'</label> : ""}
                                            </div>
                                            <div class="form-group col-md-3 datetime">
                                                <label for="concession" class="">Concession End</label>
                                                <select id="concession_month_id" class="selectListBefore" onChange={e => { this.updateValues('concessionMonth', e); this.state.validations.concessionMonth = e.target.value ? true : false; if(e.target.value) this.changListCSS("concession_month_id");}} value={this.state.project.concessionMonth} disabled={this.state.project.lastStepCompleted > 3}
                                                    style={{ border: (this.state.validations.concessionMonth === false ? '2px solid red' : '2px solid #b8b8b8') }}>
                                                    <option selected disabled>&nbsp; month</option>
                                                    {
                                                        this.state.months.map((value, index) => {
                                                            return <option value={value.id}>&nbsp; {value.name}</option>
                                                        })
                                                    }
                                                </select>
                                                <i class="fa fa-angle-down" aria-hidden="true" style={{ color: '#b8b8b8', marginRight: '10px', pointerEvents: 'none' }}></i>
                                                {this.state.validations.concessionMonth === false ? <label style={{ color: 'red', fontSize: '13px' }}>Please select concession month</label> : ""}
                                            </div>
                                            <div class="form-group col-md-3 datetime">
                                                <label for="concession" class="visibility-hide">Concession End</label>
                                                <select id="concession_year_id" class="selectListBefore" onChange={e => { this.updateValues('concessionYear', e); this.state.validations.concessionYear = e.target.value ? true : false; if(e.target.value) this.changListCSS("concession_year_id"); }} value={this.state.project.concessionYear} disabled={this.state.project.lastStepCompleted > 3}
                                                    style={{ border: (this.state.validations.concessionYear === false ? '2px solid red' : '2px solid #b8b8b8') }}>
                                                    <option selected disabled>&nbsp; year</option>
                                                    {
                                                        array.map((x, i) => { return (<option value={x}>&nbsp; {x}</option>) }
                                                        )
                                                    }
                                                </select>
                                                <i class="fa fa-angle-down" aria-hidden="true" style={{ color: '#b8b8b8', marginRight: '10px', pointerEvents: 'none' }}></i>
                                                {this.state.validations.concessionYear === false ? <label style={{ color: 'red', fontSize: '13px' }}>Please select concession year</label> : ""}
                                            </div>
                                            <div class="form-group col-md-6 datetime">
                                                <label for="concession" class="">Estimated Annualized Returns</label>
                                                <input class="form-control" onChange={e => { if (e.target.value > 100) e.target.value = 100; else if (e.target.value < 0) e.target.value = 1; this.updateValues('aggregateAnnualizedReturnPercent', e); this.state.validations.aggregateAnnualizedReturnPercent = e.target.value ? true : false; }} value={this.state.project.aggregateAnnualizedReturnPercent} disabled={this.state.project.lastStepCompleted > 3}
                                                    max="100" style={{ padding: '6px 16px', border: (this.state.validations.aggregateAnnualizedReturnPercent === false ? '2px solid red' : '2px solid #b8b8b8') }} type="number" placeholder="Example: 7"></input>
                                                {this.state.validations.aggregateAnnualizedReturnPercent === false ? <label style={{ color: 'red', fontSize: '13px' }}>Please provide aggregate annualized returns (%)</label> : ""}
                                            </div>
                                        </div>
                                        <div class="button-wrapper button-form">
                                            <button onClick={() => this.onClickSaveButton(1, "saveAndSkip")} disabled={this.state.project.lastStepCompleted > 3} type="submit" class="btn btn-secondary">Save & Skip For Now</button>
                                            <button onClick={() => this.nextTab()} type="submit" class="btn btn-primary">Proceed</button>
                                        </div>
                                    </div>
                                </div>
                                <div id="step2" className="tab-pane" style={{ padding: '0px' }}>
                                    <div class="heading-wrapper">
                                        <h4>Project Documentation</h4>
                                    </div>
                                    {
                                        this.state.message === "Please wait, we’re getting things ready." ?
                                            <div>
                                                <Loader />
                                                <h5>{this.state.message}</h5>
                                            </div> :
                                            this.state.loadProjectConfig.map((value, index) => {
                                                return (
                                                    <div key={value} className="row" style={{ color: 'black', marginTop: '30px' }}>
                                                        <div className="col" style={{ textAlign: 'left', marginTop: '10px' }}>
                                                            <p className="label">
                                                                {value.title} <label style={{ color: 'red' }}>{value.isRequired === 1 ? '*' : ''}</label>
                                                            </p>
                                                        </div>
                                                        <div className="col" align="left">
                                                            <FileUploader
                                                                id={value.id}
                                                                isFileUploaded={this.fileUploadHandler}
                                                                disabled={this.state.project.lastStepCompleted > 3}
                                                                documentConfig={value}
                                                                projectId={this.state.project.id}
                                                                onChangeValue={this.afterDocumentUploaded}
                                                                document={this.state.projectUploadedDocuments.find(d => value.id === d.documentConfigurationId)}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })
                                    }
                                    <ul className="list-inline"
                                        style={{ marginTop: '20px', pointerEvents: (this.state.message === "Please wait, we’re getting things ready." ? 'none' : ''), opacity: (this.state.message === "Please wait, we’re getting things ready." ? 0.6 : 1) }}>
                                        <div style={{ color: 'blue', textAlign: 'left', marginTop: '20px' }}>
                                            <div className="row">
                                                <div className="col-sm-2" align="center">
                                                    <button onClick={this.prevTab} type="button" className="btn btn-light" disabled={this.state.disableControlsWhileUploadingDoc} style={{ borderRadius: '0%', color: '#b8b8b8', borderColor: '#b8b8b8', width: '110px', height: '38px', backgroundColor: 'transparent', lineHeight: '0px' }}>
                                                        Back
                                                    </button>
                                                </div>
                                                <div className="col-sm-4"></div>
                                                <div className="col-sm-6" align="center">
                                                    <button type="button" onClick={() => this.onClickSaveButton(2, "saveAndSkip")} disabled={this.state.project.lastStepCompleted > 3 || this.state.disableControlsWhileUploadingDoc}
                                                        className="btn btn-light " style={{ backgroundColor: 'transparent', color: '#0099a1', borderColor: '#049ca7', width: '180px', height: '38px', borderRadius: '0%', lineHeight: '0px' }}>
                                                        <b style={{ fontWeight: 'bold', fontSize: '13px' }}>Save & Skip For Now</b>
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <button onClick={() => this.nextTab()} type="button" className="btn btn-primary" disabled={this.state.disableControlsWhileUploadingDoc || !this.isAllDocumentsUploaded()} style={{ width: '110px', height: '38px', fontColor: 'white', backgroundColor: '#0099a1', borderColor: '#049ca7', borderRadius: '0%', lineHeight: '0px' }}>
                                                        <b style={{ color: 'white', fontSize: '13px' }}>Proceed</b>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                                <div id="step3" className="tab-pane" style={{ padding: '0px' }}>
                                    <div class="heading-wrapper">
                                        <h4>Service Agreement</h4>
                                    </div>
                                    <div className="row" style={{ color: 'blue', marginTop: '30px', marginLeft: '0px' }}>
                                        <textarea rows="5" style={{ border: '1px solid #b8b8b8', color: '#b8b8b8', width: '100%', fontSize: '14px' }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                            labore et dolore magna aliqua. Vitae turpis massa sed elementum tempus. Mattis pellentesque
                                            id nibh tortor id aliquet. Massa ultricies mi quis hendrerit. Volutpat maecenas volutpat blandit
                                            aliquam etiam erat velit scelerisque. Vitae nunc sed velit dignissim sodales ut. Volutpat odio
                                            facilisis mauris sit amet massa vitae. Arcu dui vivamus arcu felis bibendum ut tristique et.
                                            Facilisis volutpat est velit egestas dui. Justo eget magna fermentum iaculis eu non diam. Dictum
                                            non consectetur a erat nam at. Risus feugiat in ante metus dictum at tempor. Egestas purus viverra
                                            accumsan in. Elementum pulvinar etiam non quam lacus. Massa sapien faucibus et molestie. Gravida
                                            dictum fusce ut placerat orci. Viverra nibh cras pulvinar mattis nunc. Vitae sapien pellentesque
                                            habitant morbi. Elementum sagittis vitae et leo duis. Bibendum arcu vitae elementum curabitur vitae
                                            nunc. Et malesuada fames ac turpis. Posuere ac ut consequat semper viverra nam libero. Vivamus at
                                            augue eget arcu. Libero enim sed faucibus turpis in eu mi. Nec sagittis aliquam malesuada bibendum
                                            arcu vitae elementum curabitur vitae. Sit amet aliquam id diam maecenas ultricies mi eget. Justo
                                            laoreet sit amet cursus sit amet dictum. Placerat duis ultricies lacus sed turpis tincidunt id.
                                            Non arcu risus quis varius quam quisque id diam vel. Morbi tristique senectus et netus et malesuada.
                                            Velit ut tortor pretium viverra suspendisse. Lectus quam id leo in vitae turpis massa sed elementum.
                                            Dignissim diam quis enim lobortis scelerisque fermentum dui. Condimentum vitae sapien pellentesque
                                            habitant morbi tristique senectus et. Ut faucibus pulvinar elementum integer enim neque volutpat.
                                            Etiam non quam lacus suspendisse faucibus interdum posuere lorem ipsum. Dignissim suspendisse in est
                                            ante in nibh mauris. Est velit egestas dui id ornare arcu. Scelerisque eu ultrices vitae auctor eu.
                                            Volutpat consequat mauris nunc congue. Mauris nunc congue nisi vitae. Augue lacus viverra vitae congue
                                            eu consequat ac felis donec. Nibh nisl condimentum id venenatis a. Ut lectus arcu bibendum at. Ipsum
                                            dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Dolor morbi non arcu risus quis varius.
                                        </textarea>
                                        <div class="custom-control custom-checkbox" style={{ textAlign: 'left', marginTop: '20px' }}>
                                            <input type="checkbox" onChange={this.handleChange} checked={this.state.project.isAgreedOnServiceAgreement} disabled={this.state.project.lastStepCompleted > 3} class="custom-control-input" id="customCheck1" />
                                            <label class="custom-control-label checkboxLbl" for="customCheck1">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing
                                                elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh mauris cursus mattis molestie
                                                a iaculis.
                                        </label>
                                        </div>

                                    </div>
                                    <ul className="list-inline" style={{ marginTop: '25px' }}>
                                        <div style={{ color: 'blue', textAlign: 'left', marginTop: '20px' }}>
                                            <div className="row">
                                                <div className="col-sm-2" align="center">
                                                    <button onClick={this.prevTab} type="button" className="btn btn-light " style={{ borderRadius: '0%', color: '#b8b8b8', borderColor: '#b8b8b8', width: '110px', height: '38px', backgroundColor: 'transparent', lineHeight: '0px' }}>
                                                        Back
                                                    </button>
                                                </div>
                                                <div className="col-sm-4"></div>
                                                <div className="col-sm-6" align="center">
                                                    <button type="button" onClick={() => this.onClickSaveButton(3, "saveAndSkip")} disabled={this.state.project.lastStepCompleted > 3}
                                                        className="btn btn-light " style={{ backgroundColor: 'transparent', color: '#0099a1', borderColor: '#049ca7', width: '180px', height: '38px', borderRadius: '0%', lineHeight: '0px' }}>
                                                        <b style={{ fontWeight: 'bold', fontSize: '13px' }}>Save & Skip For Now</b>
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <button onClick={() => this.nextTab()} disabled={!this.state.project.isAgreedOnServiceAgreement} type="button" className="btn btn-primary" style={{ width: '110px', height: '38px', fontColor: 'white', backgroundColor: '#0099a1', borderColor: '#049ca7', borderRadius: '0%', lineHeight: '0px' }}>
                                                        <b style={{ color: 'white', fontSize: '13px' }}>Proceed</b>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                                <div id="complete" className="tab-pane" style={{ padding: '0px' }}>
                                    <img className="searchIconImage" src="images/file_check.svg" />
                                    <h5 style={{ color: 'black', fontWeight: 'bold', marginBottom: '15px' }}>Done!</h5>
                                    <p style={{ color: 'black', fontSize: '14px', margin: '0px' }}>Thank you. Your application is being processed.</p>
                                    <p style={{ color: 'black', fontSize: '14px', margin: '0px' }}>It will be processed within 1 to 3 working days. You'll get notified once the token is ready to claim.</p>
                                    <ul className="list-inline" style={{ marginTop: '30px' }}>
                                        <li>
                                            <button onClick={() => this.onClickSaveButton(4, "saveAndSkip")} type="button" className="btn btn-primary" style={{ width: '180px', height: '50px', backgroundColor: '#0099a1', borderColor: '#049ca7', borderRadius: '0%', lineHeight: '0px' }}>
                                                Go To Dashboard
                               </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            );
        }
        else {
            return (
                <div className="container" style={{ width: '30%', textAlign: 'center' }}>
                    <Loader />
                    <h5>{this.state.message}</h5>
                </div>
            );
        }
    }
}
