import React from 'react';
import './ProgressBar.css';

export default class ProgressBar extends React.Component {
    render(){ 
        return(
            <div className="ProgressBarMain w-100">
                <div className="progressPBM" style={{'width' : (this.props.progress)+'%'}}>
                </div>
            </div>
        );
    }
}