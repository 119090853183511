import React from 'react';
//import './Button.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../Components/Loader/Loader';
export default class SampleComponent extends React.Component {

    render() {
        return (
            <div onClick={this.props.onClick} className={"button "  + (this.props.type) + " " +(this.props.color)}>
                <FontAwesomeIcon icon={this.props.icon}/>{this.props.label}
            </div>
        );
    }
}