import React from 'react';
import './Security.css';
import DataService from '../../DataService/DataService';
import Loader from '../../Components/Loader/Loader';
import { OtpComponent } from 'react-otp-component';

export default class Security extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            code: "",
            QRCodeData: "",
            errors: {},
            otp: "",
            GAuthCode: "",
            password: "",
            showErr: false,
            submitLoading: false,
            otpWait: false,
            otpTime: 0,
            interval: '',
        };
    }
    startTimer = (time = 2) => {
        this.setState({ otpWait: true, otpTime: time * 60 }, () => {
            let interval = setInterval(
                () => {
                    this.setState({ otpTime: (this.state.otpTime - 1) }, () => {
                        if (this.state.otpTime <= 0) {
                            clearInterval(this.state.interval);
                            this.setState({ otpWait: false })
                        }
                    })
                }, 1000
            );
            this.setState({ interval: interval });
        })
    }
    requestOTP = () => {
        this.startTimer(2);
        DataService.requestOTP().then(res => {
            if (res.data.response.success == true) {
                this.setState({ otpLoading: false });
            }
        })
    }
    componentDidMount = () => {
        DataService.getGAuthQRCode()
            .then(res => {
                if (res.data.statusCode == 200) {
                    this.setState({ code: res.data.response.code, QRCodeData: res.data.response.qrcode, loading: false });
                } else {
                    this.props.history.goBack();
                }
            });

    }

    nextTab() {
        var ul = document.getElementById('progressbar');
        if (ul) {
            var tabs = ul.getElementsByClassName('step0 inprogress');
            if (tabs[0].id === 'tab1') {
                var tab2 = document.getElementById('tab2');
                tab2.className = "step0 inprogress";
                var step1 = document.getElementById('step1');
                step1.className = "tab-pane";
                var step2 = document.getElementById('step2');
                step2.className = "tab-pane active";
            }
            if (tabs[0].id === 'tab2') {
                var tab3 = document.getElementById('tab3');
                tab3.className = "step0 inprogress";
                var step2 = document.getElementById('step2');
                step2.className = "tab-pane";
                var step3 = document.getElementById('step3');
                step3.className = "tab-pane active";
            }
            if (tabs[0].id === 'tab3') {
                var tab4 = document.getElementById('tab4');
                tab4.className = "step0 inprogress";
                var step3 = document.getElementById('step3');
                step3.className = "tab-pane";
                var step4 = document.getElementById('step4');
                step4.className = "tab-pane active";
            }
            tabs[0].className = "step0 active";
        }
    }
    processGAuth = () => {
        let errors = {};
        this.setState({ errors: errors, showErr: false }, () => {
            let valid = true;
            if (this.state.password == "") {
                errors.password = "Please enter a password";
                valid = false;
            }
            if (this.state.otp == "" || this.state.otp.length != 6) {
                errors.otp = "Please enter otp";
                valid = false;
            }
            if (this.state.GAuthCode == "" || this.state.GAuthCode.length != 6) {
                errors.GAuth = "Please enter code";
                valid = false;
            }
            this.setState({ errors: errors });
            if (!valid) return;
            this.setState({ submitLoading: true });
            DataService.setupGAUTH(this.state.password, this.state.GAuthCode, this.state.otp)
                .then(res => {
                    if (res.data.statusCode == 200) {
                        if (res.data.errors.length == 0) {
                            this.props.updateUser();
                        } else {
                            document.querySelectorAll(".otpContainer  input")
                            this.setState({ showErr: true, otp: '', GAuthCode: '', showErrMsg: "Error(s) occured: " + res.data.errors.map(v => v.message).toString() });
                        }
                    } else {
                        this.setState({ showErr: true, otp: '', GAuthCode: '', showErrMsg: "Error Status Code: " + res.data.statusCode });
                    }
                    this.setState({ submitLoading: false });
                    document.querySelectorAll(".otpContainer  input").forEach( inp => inp.value = "")

                }).catch(err => {
                    document.querySelectorAll(".otpContainer  input").forEach( inp => inp.value = "");
                    this.setState({ showErr: true, otp: '', GAuthCode: '', showErrMsg: "Error Occured: " + err.toString() });
                })


        });

    }

    render() {
        if (this.state.loading) return <Loader />
        return (
            <div class="center-content w-100">
                <div class="heading-card">
                    <h4 class="mb-20">Settings &nbsp;&gt;&nbsp; Security &nbsp;&gt;&nbsp; Google Authenticator</h4>
                    <h5 class="mb-20">Google Authentication</h5>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="inner-padding">
                                <div class="card-title">
                                    <div class="card">
                                        <div class="d-flex justify-content-center">
                                            <div class="col-12">
                                                <ul id="progressbar" class="text-center">
                                                    <li class="step0 inprogress" id="tab1"><span>Download App</span></li>
                                                    <li class="step0 inprogress" id="tab2"><span>Scan QR Code</span></li>
                                                    <li class="step0 inprogress" id="tab3"><span>Backup Key</span></li>
                                                    <li class="step0 inprogress" id="tab4"><span>Enable Google Authenticator</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane active " id="step1" >
                                        <div class="authentication-wrapper text-center">
                                            <span>Download and install the Google Authentication app.</span>
                                            <div class="authentication-img-holder">
                                                <div className="d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
                                                    <img src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en" style={{ margin: "0px", marginRight: "10px", width: '90px', height: '90px' }} alt="QR code icon" />

                                                    <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img style={{ marginBottom: "0px" }} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
                                                    <a href="https://apps.apple.com/us/app/google-authenticator/id388497605?mt=8" style={{ "display": "inline-block", overflow: "hidden", background: "url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2010-09-20&kind=iossoftware&bubble=ios_apps) no-repeat", "width": "135px", height: "40px" }}></a>
                                                    <img src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://apps.apple.com/us/app/google-authenticator/id388497605?mt=8" style={{ width: '90px', height: '90px', margin: "0px", marginLeft: "10px" }} alt="QR code icon" />

                                                </div>
                                            </div>
                                            <button onClick={() => this.nextTab()} type="button" class="mt-20 btn btn-primary">Next</button>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="step2">
                                        <div class="authentication-wrapper text-center">
                                            <span>Scan this QR code in the Google Authentication app.</span>
                                            <div class="qr-wrap">
                                                <img src={this.state.QRCodeData} style={{ width: '90px' }} alt="" />
                                                <div class="qr-text" style={{ marginLeft: '20px', textAlign: 'left' }}>
                                                    <p>If you are unable to scan the QR code.<br></br>
                                                     Please enter this code manually into the app.
                                                    </p>
                                                    <span class="highlight">{this.state.code}</span>
                                                </div>
                                            </div>
                                            <button onClick={() => this.nextTab()} type="button" class="mt-20 btn btn-primary">Next</button>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="step3" >
                                        <div class="authentication-wrapper text-center">
                                            <p style={{ fontSize: '13px' }}>Please save this key on paper. This key will allow you to recover your Google Authentication
                                             in case of phone loss.</p>
                                            <div class="qr-wrap">
                                                <div class="qr-text">
                                                    <p>Resetting your Google Authentication requires opening a support ticket and<br></br>
                                                    takes atleast 7
                                                    days to recover</p>
                                                    <span class="highlight">{this.state.code}</span>
                                                </div>
                                            </div>
                                            <button onClick={() => this.nextTab()} type="button" class="btn btn-primary">Next</button>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="step4" >
                                        <div class="authentication-wrapper text-center">
                                            <span>Enable your google authenticator:</span>
                                            <p style={{ color: '#999', fontSize: '14px', margin: '0px' }}>
                                                Resetting you Google Authentication requires opening a support ticket and
                                            </p>
                                            <p style={{ color: '#999', fontSize: '14px', margin: '0px' }}>
                                                takes alteast 7 days to process.
                                               </p>
                                            <div className="col-sm-5" style={{ marginLeft: '275px', marginTop: '15px', textAlign: 'left' }}>
                                                <div class="code-section">
                                                    <div class="form-group">
                                                        <label for="login-pwd">Login Password</label>
                                                        <input type={this.state.showPassword ? "text" : "password"} onChange={e => this.setState({ password: e.target.value })} class="form-control" id="login-pwd" placeholder="Enter Login Password" />
                                                        <a><i onClick={() => { this.setState({ showPassword: !this.state.showPassword }) }} class={"fa fa-eye" + (this.state.showPassword ? "" : "-slash")} aria-hidden="true"></i></a>
                                                        <span style={{ color: "red", fontSize: "10px", textAlign: "left" }}>{this.state.errors.password}</span>


                                                    </div>
                                                    <div class="form-group">
                                                        <div class="text-wrap">
                                                            <div style={{ display: "flex", justifyContent: "space-between",alignItems:"center" }}>
                                                                <label for="sms-auth">SMS Authentication Code</label>

                                                                <button type="button" disabled={this.state.otpWait} onClick={this.requestOTP} class="btn btn-secondary" style={{padding: "7px 3px",minWidth:"unset" }}> {this.state.otpWait ? `Wait (${parseInt((this.state.otpTime) / 60)}:${parseInt(this.state.otpTime % 60)})` : `Send SMS`}</button>
                                                            </div>
                                                            <div style={{ marginTop: "20px" }} className="otpContainer">
                                                                <OtpComponent
                                                                    size={6}
                                                                    border="2px solid black"
                                                                    style={{ padding: "0px" }}
                                                                    completeOtpEntered={otp => this.setState({ otp: otp })}
                                                                />

                                                            </div>

                                                            <span style={{ color: "red", fontSize: "10px", textAlign: "left" }}>{this.state.errors.otp}</span>

                                                        </div>

                                                    </div>
                                                    <div class="form-group">
                                                        <div class="text-wrap">
                                                            <label for="google-auth">Google Authentication Code</label>

                                                            <div style={{ marginTop: "20px" }} className="otpContainer">
                                                                <OtpComponent
                                                                    size={6}
                                                                    border="2px solid black"
                                                                    style={{ padding: "0px" }}
                                                                    completeOtpEntered={otp => this.setState({ GAuthCode: otp })}
                                                                />
                                                            </div>
                                                            {        document.querySelectorAll(".otpContainer  input").forEach( inp => inp.pattern = "\d")}

                                                            <span style={{ color: "red", display: "block", fontSize: "10px", textAlign: "left" }}>{this.state.errors.GAuth}</span>

                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" onClick={(!this.state.submitLoading ? this.processGAuth : "")} class="mt-20 btn btn-primary w-40" style={{ width: '100%' }}>Submit {this.state.submitLoading ? <i className="fa fa-circle-o-notch fa-spin" /> : ""}</button>
                                                <div className="mt-0" style={{ background: "red", color: "white", fontSize: "13px", padding: "20px", "opacity": (this.state.showErr ? "1" : "0"), "transition": "all 0.25s" }}>{this.state.showErrMsg}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
