import React, { Component } from 'react';
//import './MyProjectDetail.css';
import InputField from '../../Components/InputField/InputField';
import Button from '../../Components/Button/Button';
import logo from '../../images/coinage-logo.png';
import DataService from '../../DataService/DataService';
import Select from '../../Components/Select/Select';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../Components/Loader/Loader';
import docPic from "../../images/file_check.svg";
import noDocIcon from '../../Assets/images/no-docs.svg';
import FileUploader4 from '../../Components/FileUploader4/FileUploader4';
import _ from 'lodash';
//  import '../../scss/bootstrap/_security.scss';
class MyProjectDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            project: {},
            sectors: [],
            documents: [],
            loading: true,
            symbolValid: true,
            symbolLoading: false,
            updateLoading: false,
            errors: {
                name: "Please enter a name",
                projectSize: "Please enter a value",
                issueSize: "Please enter a value",
                concessionMonth: "Please enter a value",
                concessionYear: "Please enter a value",
                aggregateAnnualizedReturnPercent: "Please enter a value",
                symbol: "Please enter a value"
            },
            validation: {

            }
        }
    }
    projectStyle = {
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        'min-height': '200px',
        justifyContent: 'center',
        alignItems: 'center'

    }
    update = () => {
        if (!this.state.isEdit || this.state.updateLoading) return;
        let valid = true;
        let validation = { ...this.state.validation };
        let errors = { ...this.state.errors };
        Object.keys(this.state.project).forEach(key => {
            validation[key] = false;
            if (this.state.project[key] == '' && key != "balance" && key != "isAgreedOnServiceAgreement") {
                validation[key] = true;
            }
            if (key == 'concessionYear' && this.state.project[key].toString().length != 4) {
                validation[key] = true;
                errors[key] = "Please enter year in correct format (YYYY)"
            } else if (key == 'concessionMonth' && (this.state.project[key] > 12 || this.state.project[key] < 1)) {
                validation[key] = true;
                errors[key] = "Please enter valid month in format (MM)";
            } else if (key == "aggregateAnnualizedReturnPercent" && (this.state.project[key] > 100 || this.state.project[key] < 0)) {
                validation[key] = true;
                errors[key] = "Please enter value between 0 and 100";
            } else if (key == "symbol" && this.state.project[key].split(/\s+/).length > 1) {
                validation[key] = true;
                errors[key] = "Ensure that symbol does not have any spaces";
            }
            valid = !validation[key] && valid;;
        })
        this.setState({ validation: validation, errors: errors });
        if (!valid) return;
        let updatedSymbolProject = this.state.project;
        updatedSymbolProject.symbol = updatedSymbolProject.symbol + ".FIT";
        this.setState({ project: updatedSymbolProject, updateLoading: true, isEdit: false }, () => {
            DataService.updateProject(this.state.project)
                .then(res => {
                    let project = res.data.response;
                    DataService.getProjectBalance(project.id).then(res => {
                        project.balance = res.data.response.balance == null ? "" : res.data.response.balance;
                        project.symbol = project.symbol.replace(/\.FIT/,"");
                        this.setState({ project: project, disabled: res.data.response.lastStepCompleted > 3 ? true : false }, () => {
                            //Get Project Documents after getting project
                            DataService.getProjectDocuments(this.state.project.id).then(res => {
                                this.setState({ documents: res.data.response, updateLoading: false });
                            });
                        });
                    })
                });
        });



    }
    componentDidMount() {
        let id = this.props.location.pathname.split('/').slice(-1).pop();
        DataService.getProject(id)

            .then(res => {
                let project = res.data.response;
                DataService.getProjectBalance(project.id).then(res => {
                    project.balance = res.data.response.balance == null ? "" : res.data.response.balance;
                    project.symbol = project.symbol.replace(/\.FIT/,"");
                    this.setState({ project: project, disabled: project.lastStepCompleted > 3 }, () => {
                        //Get Project Documents after getting project

                        DataService.getProjectDocuments(this.state.project.id).then(res => {
                            this.setState({ documents: res.data.response, loading: false });
                        });
                    });
                })
                console.log(res.data);
            });
        DataService.getSectors().
            then(res => this.setState({ sectors: res.data.response.data }));

    }
    changeProjectDetail = e => {
        console.log(e.target.value);
        console.log(e.target.value.match(/-+/g));
        if ((e.target.id == "aggregateAnnualizedReturnPercent") && (e.target.value < 0 || e.target.value > 100)) {
            let validation = this.state.validation;
            validation[e.target.id] = true;
            let errors = this.state.errors;
            errors[e.target.id] = "Please enter a value in correct range (0-100)";
            this.setState({ validation: validation, errors: errors });
            return;
        } else if ((e.target.id == "projectSize" || e.target.id == "issueSize") && (e.target.value.match(/[^\d\.]+/g) != null || parseInt(e.target.value) <= 0)) {
            let validation = this.state.validation;
            validation[e.target.id] = true;
            let errors = this.state.errors;
            errors[e.target.id] = "Please enter a positive value";
            this.setState({ validation: validation, errors: errors });
            return;
        }
        else {
            let validation = this.state.validation;
            validation[e.target.id] = false;
            this.setState({ validation: validation });
        }
        let id = e.target.id, value = e.target.value;
        this.setState((prevState) => {
            let project = { ...prevState.project };
            project[id] = value;
            return { project };
        })
    }
    commaSeparated(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    debounce(func, wait, immediate) {
        var timeout;

        return function executedFunction() {
            var context = this;
            var args = arguments;

            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };

            var callNow = immediate && !timeout;

            clearTimeout(timeout);

            timeout = setTimeout(later, wait);

            if (callNow) func.apply(context, args);
        };
    };
    updateSymbol = e => {
        this.setState({ project: { ...this.state.project, symbol: e.target.value.toUpperCase() } }, () => this.debouncedSymbolEdit());
    }
    setDocument = (e, oldID) => {
        let documents = this.state.documents;
        let index = documents.findIndex(d => d.id === oldID);
        if (index === -1) return;
        documents[index] = e;
        this.setState({ documents: documents });
    }
    updateSymbolValue = () => {
        this.setState({ symbolLoading: true })
        DataService.checkProjectSymbolEdit(this.state.project.symbol + ".FIT", this.state.project.id)
            .then(res => {
                if (res.data.response == true) {
                    this.setState({ symbolValid: true, symbolLoading: false });
                } else {
                    this.setState({ symbolValid: false, symbolLoading: false });
                }
            })
    }
    debouncedSymbolEdit = _.debounce(this.updateSymbolValue, 500);

    render() {
        if (this.state.loading) return <Loader />
        let year = [];
        for (let abc = 2020; abc <= 2050; abc++) year.push(abc);
        const months = [{ id: 1, name: 'Jan' },
        { id: 2, name: 'Feb' },
        { id: 3, name: 'Mar' },
        { id: 4, name: 'Apr' },
        { id: 5, name: 'May' },
        { id: 6, name: 'Jun' },
        { id: 7, name: 'Jul' },
        { id: 8, name: 'Aug' },
        { id: 9, name: 'Sep' },
        { id: 10, name: 'Oct' },
        { id: 11, name: 'Nov' },
        { id: 12, name: 'Dec' }]
        let ErrorLabel = id => this.state.validation[id] == true ? <label style={{ color: "red" }}>{this.state.errors[id]}</label> : <label>Lalala</label>
        let noDocumentsJSX = <div style={this.projectStyle} className="col">
            <img src={noDocIcon} style={{ marginTop: "30px" }} width="200px" />
            <span style={{ fontSize: "20px", fontWeight: '500' }}>No documents</span>
            <span>Looks like we have no documents. Lets add some!</span>
            <div className=" button-wrapper w-80 " style={{ marginTop: "40px", marginBottom: "40px" }}>
                <button onClick={() => { localStorage.setItem("projectId", this.state.project.id); window.location.href = "/tokenize"; }} type="button" style={{ "padding": "10px 25px" }}
                    className=" btn btn-primary w-80">Complete your project</button>
            </div>
        </div>
        let documentsJSX = this.state.documents.map(document => <div className="col-md-6 col-lg-4 d-flex align-items-stretch">
            <div id={document.id} className="inner-padding w-100" >
                <img  style={{"cursor":"pointer",objectFit:"cover",objectPosition:"top"}}  onClick={() => window.open(`https://api.fasset.com${document.url}`)} target="_blank" className="card-img-top" src={document.thumbnail == null ? docPic : ("https://api.fasset.com" + document.thumbnail+`?i=${Date.now()}`)}  onError={() => {
                    let index = this.state.documents.findIndex(d => d.id === document.id); 
                    if (index !== -1) {
                        let documents = this.state.documents;
                        documents[index].thumbnail = null;
                        this.setState({ documents: documents });
                    }
                }} alt="Card image cap" />
                <div className="card-body">
                    <h5 className="card-title">{document.name}</h5>
                    <div className="card-text">
                        <p>{document.url.split("/").slice(-1).pop()}</p>
                        <small className="text-muted">{document.fileSizeInMbs} MB</small>
                    </div>
                </div>
                {this.state.disabled ? "" :
                    <FileUploader4 setDocument={this.setDocument} projectId={this.state.project.id} id={document.id} documentConfig={document.documentConfiguration} document={document} />}

            </div>
        </div>)
        return (
            <div className="center-content project-wrapper my-project-detail" id="my-projects">
                <div className="project-cards">
                    <h4 className="mb-20"><span style={{ cursor: "pointer" }} onClick={() => this.props.history.push("/myprojects")}>My Projects</span> &nbsp;{">"}&nbsp; {this.state.project.symbol}</h4>
                    <ul className="tabs-section">
                        <li className="active"><a href="#">Project Overview</a></li>
                        <div className="card-overlay" style={{ "position": "relative", display: "inline-block" }}>
                            <li ><a href="#">Investors</a></li>
                            <li><a href="#">Configuration</a></li>
                            <li><a href="#">Dividens</a></li>
                        </div>
                    </ul>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="inner-padding">
                                <div className="project-disc">
                                    <div className="project-status mb-20">
                                        <h5 className="float-left">Project Details</h5>
                                        <span style={{ padding: "5px 15px", ...this.state.project.status.name.toLowerCase() !== "inprogress" ? { backgroundColor: "#A4DE02", borderColor: "#A4DE02", color: "white" } : {} }} className={"float-right " + (this.state.project.status.name == "InProgress" ? "inprocess" : "complete")}>{this.state.project.status.name}</span>
                                    </div>
                                    <div className="form-wrapper">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label for="project">Project Name</label>
                                                <input type="text" className="form-control" id="name" value={this.state.project.name} onChange={this.changeProjectDetail} disabled={(this.state.isEdit ? this.state.disabled : true)} placeholder="Example: Abu Dhabi Solar Panel" />
                                                {this.state.validation.name == true ? <label style={{ color: "red" }}>{this.state.errors.name}</label> : ""}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="project-num">Project Number</label>
                                                <input type="text" className="form-control" id="id" disabled value={this.state.project.id} onChange={this.changeProjectDetail} placeholder="AB972688989988" />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="symbol">Project Symbol</label>
                                                <span style={{display:"flex"}}>
                                                    <input type="text" className="form-control" id="symbol" value={this.state.project.symbol} onChange={this.updateSymbol} disabled={(this.state.isEdit ? this.state.disabled : true)} placeholder="MFTR" />
                                                    <i style={{marginRight:50}} class={"fa " + (this.state.symbolLoading ? "fa-circle-o-notch fa-spin" : (this.state.symbolValid ? "fa-check" : "fa-warning"))}></i>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" style={{ border: ((this.state.validation.symbol && this.state.validation.symbol !== false) ? '2px solid red' : '2px solid #b8b8b8') }}>
                                                            <label style={{ marginTop: '2px',color:"RED", fontWeight: '500' }}>.FIT</label>
                                                        </span>
                                                    </div>
                                                </span>

                                                {this.state.validation.symbol == true ? <label style={{ color: "red" }}>{this.state.errors.symbol}</label> : ""}

                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="sectorId">Sector</label>
                                                <select id="sectorId" class="form-control" value={this.state.project.sector.id} disabled={(this.state.isEdit ? this.state.disabled : true)} onChange={this.changeProjectDetail}>
                                                    {this.state.sectors.map(sector => <option value={sector.id}>{sector.name}</option>)}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="projectSize">Project Size</label>
                                                <input type="text" value={this.state.project.projectSize} onChange={this.changeProjectDetail} disabled={(this.state.isEdit ? this.state.disabled : true)} className="form-control" id="projectSize"
                                                    placeholder="Example: 200,000,000,000,000" />
                                                {this.state.validation.projectSize == true ? <label style={{ color: "red" }}>{this.state.errors.projectSize}</label> : ""}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="issueSize">Issue Size</label>
                                                <input type="text" className="form-control" onChange={this.changeProjectDetail} disabled={(this.state.isEdit ? this.state.disabled : true)} value={this.state.project.issueSize} id="issueSize"
                                                    placeholder="Example: 30,000,000,000,000" />
                                                {this.state.validation.issueSize == true ? <label style={{ color: "red" }}>{this.state.errors.issueSize}</label> : ""}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="concessionYear">Concession Month</label>

                                                <select onChange={this.changeProjectDetail} class="form-control" id="concessionMonth" value={this.state.project.concessionMonth} disabled={(this.state.isEdit ? this.state.disabled : true)}
                                                >
                                                    <option selected disabled>&nbsp; month</option>
                                                    {
                                                        months.map((value, index) => {
                                                            return <option value={value.id}>&nbsp; {value.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="concessionYear">Concession Year</label>
                                                <select onChange={this.changeProjectDetail} id="concessionYear" value={this.state.project.concessionYear} disabled={(this.state.isEdit ? this.state.disabled : true)}
                                                    class="form-control" >
                                                    <option selected disabled>&nbsp; year</option>
                                                    {
                                                        year.map((x, i) => { return (<option value={x}>&nbsp; {x}</option>) }
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6 ">
                                                <label for="concession" className="col-12">Aggregate Annualized Returns</label>
                                                <input type="number" id="aggregateAnnualizedReturnPercent" className="form-control" value={this.state.project.aggregateAnnualizedReturnPercent} disabled={(this.state.isEdit ? this.state.disabled : true)} onChange={this.changeProjectDetail} placeholder="Example: 7%" />
                                                {this.state.validation.aggregateAnnualizedReturnPercent == true ? <label style={{ color: "red" }}>{this.state.errors.aggregateAnnualizedReturnPercent}</label> : ""}
                                            </div>
                                        </div>
                                        <div className="button-wrapper">
                                            <button type="submit" style={{ opacity: this.state.isEdit ? "1" : "0", transition: "all 0.25s", "margin-right": "20px" }} onClick={(this.state.isEdit ? this.update : false)} disabled={(this.state.disabled ? "disabled" : (this.state.symbolLoading || !this.state.symbolValid))} className="btn btn-primary">{this.state.updateLoading ? <i className="fa fa-circle-o-notch fa-spin" /> : "Save"}</button>
                                            <button type="submit" onClick={() => this.setState({ isEdit: !this.state.isEdit })} disabled={(this.state.disabled ? "disabled" : (this.state.symbolLoading || !this.state.symbolValid))} className={"btn btn-" + (this.state.isEdit ? "secondary" : "primary")}>{this.state.updateLoading ? <i className="fa fa-circle-o-notch fa-spin" /> : (this.state.isEdit ? "Cancel" : "Edit")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 d-flex">
                            <div className="inner-padding content-right  w-100">
                                <h5>{this.state.project.symbol} Wallet Balance</h5>
                                <div className="content-job">
                                    <div className="heading-wrapper text-center">
                                        <p>Native Token Balance</p>
                                        <strong>{this.state.project.balance == "" ? ["Awaiting Tokenization", <i className="fa fa-exclamation-circle"></i>] : this.state.project.balance}</strong>
                                    </div>
                                    <button type="button" disabled={this.state.project.balance == ""} onClick={() => { localStorage.setItem("projectId", this.state.project.id); this.props.history.push("/wallet/transfer") }} className="btn btn-secondary">Withdraw</button>
                                    <button type="button" disabled className="btn btn-primary card-overlay" style={{ position: "relative" }}>Offering</button>
                                </div>
                                <div className="content-job card-overlay" style={{ position: "relative" }} >
                                    <div className="heading-wrapper text-center">
                                        <p>Proceeds Balance</p>
                                        <strong>0,000,000 USD</strong>
                                    </div>
                                    <div id="chartContainer" style={{ "height": "200px", "width": "100%" }}></div>
                                    <div className="chart-disc">
                                        <i className="fa fa-square-full"></i>
                                        <span>USD</span>
                                        <strong>0,000,000 USD (60%)</strong>
                                    </div>
                                    <div className="chart-disc BTC">
                                        <i className="fa fa-square-full BTC"></i>
                                        <span>USD</span>
                                        <strong>0.0000 = 0,000,000 USD (25%)</strong>
                                    </div>
                                    <div className="chart-disc BTH">
                                        <i className="fa fa-square-full BTH"></i>
                                        <span>USD</span>
                                        <strong>0.0000 = 0,000,000 USD (15%)</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="document-section">
                        <h4 className="mb-20">Documentation</h4>
                        <div className="row">

                            {this.state.documents.length != 0 ? documentsJSX : noDocumentsJSX}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default MyProjectDetail;