import React from 'react';
import './FileUploader.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fileIcon from '../../images/file-icon.png';
import fileUploadIcon from '../../images/upload-icon.png';
import DataService from '../../DataService/DataService';
import ProgressBar from '../../Components/ProgressBar/ProgressBar';
import { ToastContainer, toast } from 'react-toastify';

const SELECTED = '3', UPLOADING = '2', NOFILE = '1';
const NOTUPLOADED = -1;
export default class FileUploader2 extends React.Component {

    constructor(props) {
        super(props);
        let file = {

        };

        let status = NOFILE;
        let uploadedId = NOTUPLOADED;
        if (this.props.document != undefined) {
            file.size = this.props.document.fileSizeInMbs;
            file.name = this.props.document.name;
            uploadedId = this.props.document.id;
            status = SELECTED;
        }


        this.state = {
            file: file,
            status: status,
            progress: 0,
            uploadedId: uploadedId,
            fileError: '',
        };
    }
    componentDidMount() {
        window.addEventListener("dragover", function (e) {
            e = e;
            e.preventDefault();
        }, false);
        window.addEventListener("drop", function (e) {
            e = e;
            e.preventDefault();
        }, false);

        var container = document.getElementById(this.props.id);
        container.addEventListener("drop", (e) => {
            let event = e;
            this.uploadFile(event);
            e.preventDefault();
        })
    }
    displayError = error => {
        this.setState({ fileError: error }, () => {
            setTimeout(() => this.setState({ fileError: "" }), 6000);
        })
    }
    uploadFile = e => {
        if (this.state.status == UPLOADING) return;
        this.props.isFileUploaded(true);
        let body = new FormData();
        let file = e.target.type == "file" ? e.target.files[0] : e.dataTransfer.files[0];
        if (file.size > 5e06) {
            this.displayError("File is too big. Please upload under 5MB");
            this.props.isFileUploaded(false);
            return;
        } else if (this.props.documentConfig.allowedFileExts.split(",").find(f => f.replace(".", "") == file.name.split(".").slice(-1).pop()) == undefined) {
            this.displayError("Wrong File Type Allowed Files are " + this.props.documentConfig.allowedFileExts);
            this.props.isFileUploaded(false);
            return;
        }
        body.append(
            "file",
            file,
            file.name
        );
        body.append("documentConfigurationId", this.props.id);
        body.append("projectId", this.props.projectId);
        if (this.state.uploadedId != NOTUPLOADED) body.append("id", this.state.uploadedId);
        this.setState({ status: UPLOADING, progress: 0,file: {name: body.get("file").name, size:body.get("file").size/1e06} }, () => {
            DataService.uploadDocument(body, this.setProgress)
                .then(res => {
                    this.setState({ status: SELECTED, file: { name: res.data.response.name, size: res.data.response.fileSizeInMbs }, uploadedId: res.data.response.id })
                    if (this.props.documentConfig.isRequired === 1) this.props.onChangeValue(res.data.response.id);
                    this.props.isFileUploaded(false);
                })
                .catch(err => {
                    let file = {
                    };
                    let status = NOFILE;
                    let uploadedId = NOTUPLOADED;
                    if (this.props.document != undefined) {
                        file.size = this.props.document.fileSizeInMbs;
                        file.name = this.props.document.name;
                        uploadedId = this.props.document.id;
                        status = SELECTED;
                    }


                    this.setState({
                        file: file,
                        status: status,
                        progress: 0,
                        uploadedId: uploadedId,
                    });
                    toast.error(`An error occured: ${err.toString()}`);
                    this.props.isFileUploaded(false);
                });
        });
    }
    setProgress = progressEvent => {
        let ratio = progressEvent.loaded / progressEvent.total;
        let percent = ratio * 100;

        this.setState({ progress: percent.toPrecision(3),file: {...this.state.file,size: ((this.state.file.size - (ratio*this.state.file.size))).toPrecision(3)} });

        console.log(percent.toPrecision(3));
    }
    startUpload = () => {
        //Begin Uploading File and tracking state
    }
    calculateFileSizeString(bytes) {
        if (bytes > 1e06) {
            return (bytes / 1e06).toPrecision(4) + ' MB';
        } else if (bytes > 1e03) {
            return (bytes / 1e03).toPrecision(4) + " KB";
        }
        return bytes + " Bytes"
    }
    render() {
        var selectedJSX = <div className="selected">
            <div className="left">
                {this.state.status === UPLOADING ? <span className="progressTop">
                    <span>{this.state.progress}%</span>
                    <ProgressBar progress={this.state.progress} />
                </span>
                    :

                    <span style={{textAlign:"left"}} className="top">{this.state.fileError == "" ? "Upload Complete" : this.state.fileError}</span>}
                <span style={{ 'flex-direction': 'column',textAlign:"left" }} className="bottom"><span style={{textAlign:"left"}}>{this.state.file.name && this.state.file.name.slice(0, 21) + (this.state.file.name.length > 21 ? "..." : "")}</span>{this.state.file.size} MB</span>
            </div>
            {this.state.status === SELECTED ? [<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><label className='uploadbtn' >Change File</label> <input type='file' accept={this.props.documentConfig.allowedFileExts} name={this.props.documentConfig.id} onChange={this.uploadFile} id={"file" + this.props.documentConfig.id} /></div>] : ''}

        </div>

        return (

            <div className="FLMain" onDragOver={(e) => {clearTimeout(this.state.timeout);this.setState({ fileOver: true, targetDrag: e.target });}} onMouseOut={(e) => { this.setState({ fileOver: false}) }} onClick={() => { document.getElementById(`btnFile${this.props.documentConfig.id}`).click() }} id={this.props.id} style={{ pointerEvents: this.props.disabled ? 'none' : '',position:"relative" }}>

                <img src={this.state.selected ? fileIcon : fileUploadIcon} width='30px' />

                <label id={`btnFile${this.props.documentConfig.id}`} htmlFor={"file" + this.props.documentConfig.id} hidden></label>
                {this.state.status !== NOFILE ? selectedJSX : [<label >{this.state.fileError != "" ? this.state.fileError : "Drag file or click to upload"}</label>,
                <input type='file' accept={this.props.documentConfig.allowedFileExts} onChange={this.uploadFile} id={"file" + this.props.documentConfig.id} />]}
                
                <div onDragLeave={() => this.setState({timeout:setTimeout(() => this.setState({fileOver:false}),500)})} style={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0, backgroundColor: "white", display: this.state.fileOver?"flex":"none",justifyContent: "center", alignItems: "center" }}>
                    <img src={this.state.selected ? fileIcon : fileUploadIcon} width='30px' />

                </div>
            </div>
        );
    }
}
