import React, { Component } from 'react';
import './Dashboard.css';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Overview from '../../ViewComponents/Overview/Overview';
import Security from '../../ViewComponents/Security/Security';
import {
    BrowserRouter as Router,
    HashRouter,
    Switch,
    Route,
    Link
} from "react-router-dom";
import MyProjects from '../../ViewComponents/MyProjects/MyProjects';
import MyProjectDetail from '../../ViewComponents/MyProjectDetail/MyProjectDetail';
import MyProfile from '../../ViewComponents/MyProfile/MyProfile';
import WalletBalances from '../../ViewComponents/WalletBalances/WalletBalances';
import SecurityManagment from '../../ViewComponents/SecurityManagment/SecurityManagment';
import welcomeImg from "../../images/welcome-icon.png"
import AdminPanel from '../../ViewComponents/AdminPanel/AdminPanel';
import Button from '../../Components/Button/Button';
import Withdraw from '../../ViewComponents/Withdraw/Withdraw';
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { collapse: false, updateSelection: true }
    }
    componentDidMount() {
        if (window.location.href.match(/first-login/) != null) {
            this.setState({newLogin: true});
        }
    }
    clearSelection = () =>  {
        this.setState({updateSelection: false});
    }
    updateSidebar = () => {
        this.setState({updateSelection: true});
    }
    render() {
        return (
            <HashRouter>

                <div className="wrapper">
                    <Sidebar {...this.props} active={this.props.sidebarActive} updateSelection={this.state.updateSelection} clearSelection={this.clearSelection} collapse={this.state.collapse} user={this.props.user} />
                    <Switch>
                        <Route path="/admin" component={AdminPanel} />
                        <Route path="/wallet/withdraw" render = {props => < Withdraw {...props} />}/>
                        <Route path='/wallet' render = {props => <WalletBalances {...props}/>} />
                        <Route path='/myprofile' render={(props) => <MyProfile {...props} setUser={this.props.setUser} user={this.props.user} />} />
                        <Route path='/myprojects/detail' component={MyProjectDetail} />
                        <Route path='/myprojects' component={MyProjects} />
                        <Route path="/security/gauth" component={Security}/>
                        <Route path='/security' render={(props) => <SecurityManagment {...props} />}/>
                        <Route path="/" component={Overview} />
                    </Switch>
                </div>
                    <div class={"modal fade " + (this.state.newLogin? "show": "")} id="exampleModalCenter" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header text-center">
                                    <img src={welcomeImg} alt="Welcome to Fasset Dashboard" />
                                    <h5 class="modal-title mt-20 mb-20" id="exampleModalLongTitle">Welcome to the Fasset Dashboard</h5>
                                    <button type="button" onClick={() => {window.location.href="/tokenize"}} class="btn btn-primary">Start Tokenization Process</button>
                                    <span>Need Help? <a href="#">Click here</a></span>
                                </div>
                                <div class="modal-footer">
                                    <a class="float-right close" data-dismiss="modal" onClick={() => this.setState({newLogin:false})}aria-label="Close" href="#">Skip</a>
                                </div>
                            </div>
                        </div>
                    </div>
            </HashRouter>

        );
    }
}

export default Dashboard;