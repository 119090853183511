import React from 'react';
import { Link } from 'react-router-dom'
//import './Navbar.css';
import logo from '../../images/coinage-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dropdown from 'react-dropdown';
export default class Navbar extends React.Component {
    constructor() {
        super();
        this.state = {
            show: false
        }
    }
    render() {
        let options = ['English', 'Arabic', 'German'];
        let defaultOption = options[0];
        return (
            <header className="header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                    {this.props.noSidebar? "":
                        <button type="button" onClick={this.props.toggleSidebar} id="sidebarCollapse" class="open-dashboard d-lg-none btn btn-secondary">
                            <i class="fa fa-align-left"></i>
                        </button>
                    }
                        <a className="navbar-brand text-left" href="/"><img src={logo} alt="Fasset logo" /></a>
                        
                        <button className="navbar-toggler" onClick={() => { this.setState({ show: !this.state.show }) }} type="button" data-toggle="collapse" data-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={"collapse navbar-collapse justify-content-lg-end " + (this.state.show ? "show" : "")} id="navbarNav">
                            <ul className="navbar-nav align-items-start align-items-lg-center">
                                <li className="nav-item">
                                    <a className="nav-link" onClick = {() => {window.location.href="/support"}}>Support <span className="sr-only">(current)</span></a>
                                </li>
                                <li className="nav-item">

                                    {this.props.authenticated ?
                                        <a className="nav-link" onClick={() => { localStorage.removeItem("token");this.setState({user: {},authenticate:false}); window.location.href = "/" }}>Sign Out</a> : <a className="nav-link" href="/">Sign In</a>}
                                    
                                </li>:


                    <li className="nav-item dropdown pr-0">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        English
                      </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <a className="dropdown-item" href="#">Arabic</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}