import React from 'react';

//import './Sidebar.css';
import overviewIcon from '../../images/overview-icon.png';
import adminPanelIcon from '../../images/admin-panel-icon.png';
import settingIcon from '../../images/account-icon.png';
import myWalletIcon from '../../images/my-wallet-icon.png';
import myProjectIcon from '../../images/my-project-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import userImage from '../../images/user-img.png';
import { Link } from "react-router-dom";

export default class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        let routeRegex = /overview|admin|myprojects|wallet|myprofile|security/g;
        let matches = window.location.href.match(routeRegex);
        this.state = {
            current: (matches == null ? 'overview' : matches[0])
        }

    }

    render() {
        let routeRegex = /overview|admin|myprojects|wallet|myprofile|security/g;
        let matches = window.location.href.match(routeRegex);
        let current = (matches == null ? 'overview' : matches[0]);
        if (current !== this.state.current) {
            this.setState({
                current: current
            });
        }
        return (
            <aside id="sidebar" class={this.props.active ? "active" : ""}>
                <div className="sidebar-header">
                    <img src={userImage} alt="User Image" />
                    <div className="sidebar-header-text">
                        <h4>{this.props.user.fullName}</h4>
                        <span>{this.props.user.companyName.slice(0, 19) + (this.props.user.companyName.length >= 22 ? "..." : "")}</span>
                        <small>ID: {this.props.user.id}</small>
                    </div>
                </div>

                <ul className="list-unstyled components">
                    <li className={this.state.current == "overview" ? "active" : ""}>
                        <Link to="/overview" onClick={() => { this.setState({ current: 'overview' }) }}>
                            <img src={overviewIcon} alt="Overview icon" />
                            <span>Overview</span>
                        </Link>
                    </li>

                    <li className={this.state.current == "myprojects" ? "active" : ""}>
                        <Link to="/myprojects" onClick={() => { this.setState({ current: 'myprojects' }) }}>
                            <img src={myProjectIcon} alt="My Project Icon" />
                            <span>My Projects</span>
                        </Link>
                    </li>
                    <li className={this.state.current == "wallet" ? "active" : ""}>
                        <Link to="/wallet" onClick={() => { this.setState({ current: 'wallet' }) }}>
                            <img src={myWalletIcon} alt="Wallet Icon" />
                            <span>Wallet Balance</span>
                        </Link>
                    </li>
                    <li className={this.state.current == "admin" ? "active" : ""}>
                        <Link to="/admin" onClick={() => { this.setState({ current: 'admin' }) }}>
                            <img src={adminPanelIcon} alt="Admin Panel icon" />
                            <span>Admin Panel</span>
                        </Link>
                    </li>
                    <li style={this.state.current.match(/myprofile|security/) != null ? { display: "block !important" } : {}} className={this.state.current.match(/myprofile|security/) != null ? "active" : ""}>
                        <a >
                            <img src={settingIcon} alt="Account Icon" />
                            <span>Settings</span><i className="fa fa-chevron-down float-right"></i>
                        </a>
                        <ul>
                            <li className={(this.state.current == "myprofile" ? "active " : "") + (this.state.current.match(/myprofile|security/) != null ? "displayBlock" : "")}>
                                <Link to="/myprofile" onClick={() => { this.setState({ current: 'myprofile' }) }}>
                                    <img src={settingIcon} alt="Profile icon" /><span>Profile</span>
                                </Link>
                            </li>
                            <li className={(this.state.current == "security  " ? "active " : "") + (this.state.current.match(/myprofile|security/) != null ? "displayBlock" : "")}>
                                <Link to="/security" onClick={() => { this.setState({ current: 'security' }) }}>
                                    <img src={overviewIcon} alt="Security icon" /><span>Security</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </aside>
        );
    }
}