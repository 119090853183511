import React from 'react';
import Button from '../Button/Button'
import ProgressBar from '../ProgressBar/ProgressBar'
//import './SideProjectList.css';

export default class SideProjectList extends React.Component {
    render() {
        return(
            <div className="SDLMain">
            <div className="left">
                <span className="SDLtitle">{this.props.title}</span>
                <span className="SDLcode">{this.props.code}</span>
                <span className="SDLid">{this.props.id}</span>
                <div className="progressSDL">
                    <span className="SDLstatus">
                        {this.props.progress}% completed
                    </span>
                    <ProgressBar progress={this.props.progress}/>
                </div>
            </div>
            <div className="right">
            <span className="buttonContainer">
                <Button type="filled" color="primary" onClick = {() => window.location.href = `/tokenize/${this.props.id}`} label="Continue"/>
           </span>
            
            </div>
            </div>
        );
    }
}