import React, { Component } from 'react';
class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <div id="help-section" >
        <div className="help-section">
          <div className="center-content card-overlay" style={{position:"relative"}}>
            <h1 className="mb-50">How can we help ? </h1>
            <div className="form-group has-search mb-50">
              <span className="fa fa-search form-control-feedback"></span>
              <input type="text" className="form-control" placeholder="Search" />
            </div>
            <div className="help-card">
              <div className="row">
                <div className="col-md-3">
                  <span><img src="images/account.svg" alt="Account icon" />Account</span>
                </div>
                <div className="col-md-3">
                  <span><img src="images/kyc.svg" alt="KYC icon" />KYC</span>
                </div>
                <div className="col-md-3">

                  <span><img src="images/tokens.svg" alt="Token Icon" />Token</span>
                </div>
                <div className="col-md-3">

                  <span><img src="images/finance.svg" alt="Finance icon" />Finance</span>
                </div>
                <div className="col-md-3">
                  <span><img src="images/project.svg" alt="Project icon" />Projects</span>
                </div>
                <div className="col-md-3">
                  <span><img src="images/security.svg" alt="Security icon" />Security</span>
                </div>
                <div className="col-md-3">
                  <span><img src="images/withdraw.svg" alt="Withdrawal icon" />Withdrawal</span>
                </div>
                <div className="col-md-3">
                  <span><img src="images/glossary.svg" alt="Glossary icon" />Glossary</span>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer text-center">
            <div className="more">
              <strong>Need some more help?</strong>
              <p>Contact directly with one of our client engagement specialists about your specific needs</p>
              <div className="button-wrapper text-center">
                <button  type="button" onClick={() => {window.open("mailto:support@fasset.com")}} className="btn btn-secondary big-btn">Email</button>
                <button  type="button" onClick={() => {window.open("https://calendly.com")}} className="btn btn-primary big-btn">Schedule a Call</button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default Support;