import React from 'react';
//import InputField from "../../Components/InputField/InputField";
//import Button from "../../Components/Button/Button";
import DataService from "../../DataService/DataService";
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../Components/Loader/Loader';
import { Redirect } from 'react-router';
import '../../Assets/css/main.css';

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', password: '', redirect: false, redirectURL: '' };

    }
    onFieldChange = e => {
        this.setState({ errLogin: false });
        let newObj = {};
        newObj[e.target.id] = e.target.value;
        this.setState(newObj);
    }
    componentDidMount = () => {
        if (window.location.href.toString().match(/first-login/) != null) {
            this.setState({ newLogin: true });
            toast.success("Use your new password");
        }
        try {
            console.log(this.props.location);
            if (this.props.location.pathname.split("/").slice(-1).pop() == "resetsuccess") {
                //toast.success("Use your new password");
            }
        } catch (exception) {

        }
        try {
            if (this.props.location.pathname.match(/register/) != null) {

                this.setState({ loading: true });
                let tokenRegex = /(\/token\/)([^\/]*)(\/)/;
                let emailRegex = /(\/email\/)([^\/]*)/;
                let token = this.props.location.pathname.match(tokenRegex)[2];
                let email = this.props.location.pathname.match(emailRegex)[2];
                console.log(token + " " + email);
                token = decodeURIComponent(token);
                email = decodeURIComponent(email);
                DataService.verifyUser(encodeURIComponent(token), encodeURIComponent(email))
                    .then(res => {
                        if (res.data.response == false) {
                            toast.warn("Could not verify token. Please try again");
                            this.setState({ loading: false });
                        } else {
                            toast.success("Verified Sucesfully. Please login");
                            this.setState({ loading: false });
                        }
                    })
            }
        } catch (exception) {

        }
        if (localStorage.getItem("waitForReset") != null) {
            this.setState({displayMessage: true,message:"An email has been sent to the address you have provided. Please follow the instruction in the email to reset your password.",background:"#008787" });
            localStorage.removeItem("waitForReset");
        }
        if (localStorage.getItem("newPassword") == "1") {
            this.setState({displayMessage: true,message:"Use your new password.",background:"#008787" });
            localStorage.removeItem("newPassword");
        } else  if (localStorage.getItem("newPassword") == "0") {
            this.setState({displayMessage: true,message:"The password reset failed.",background:"red" });
            localStorage.removeItem("newPassword");

        }
    }
    login = e => {
        e.preventDefault();
        this.StartLogin();
    }
    StartLogin = () => {
        this.setState({ errLogin: false, errEmail: false, errPass: false, loading: true });
        let valid = true;
        let errors = {};
        if (this.state.email == '') {
            errors.errEmail = true;
            valid = false;
        }
        if (this.state.password == '') {
            errors.errPass = true;
            valid = false;
        }
        this.setState(errors);
        if (!valid) return;
        DataService.doLogin({ email: this.state.email, password: this.state.password })
            .then(res => res).then(res => {
                console.log(res);

                if (res.data.errors.length == 0) {
                    let update = { user: res.data.response, authenticate: true }
                    if (res.data.response.isChangePasswordReqEnabled == 1) {
                        update.newUser = true;
                        localStorage.setItem("firstLogin","1");
                        debugger;
                        this.props.setUser(update);


                    } else {
                        localStorage.setItem("token", res.data.response.accessToken);
                        window.location.href = "/";
                    }
                    if (this.state.newLogin) window.location.href = "/document-verification/first-login";

                } else {
                    this.setState({ loading:false, displayMessage: true, message: "Incorrect Email or Password",password:"", background:"red" });
                }

            }).catch(err => {
                this.setState({ loading: false,displayMessage:true, message:"Unknown Error",background:"red" });
            })
    }
    render() {
        if (this.state.redirect) return <Redirect to={this.state.redirectURL} />
        return (
            <main className="main-content login-wrapper" id="login-wrapper">
                <h2>Log In</h2>
                <form className="login-form inner-wrapper" onSubmit={this.state.loading?"":this.login}>
                    <div className="form-group">
                        <label for="exampleInputEmail1">Email address</label>
                        <input type="email" id="email" class="form-control" onChange={this.onFieldChange} aria-describedby="emailHelp"
                            placeholder="Enter email" required />
                        <small id="emailHelp" class="form-text text-muted d-none">We'll never share your email with anyone
            else.</small>
                    </div>
                    <div className="form-group">
                        <label for="password">Password</label>
                        <input type={this.state.showPassword?"text":"password"} className="form-control" value={this.state.password} id="password" onChange={this.onFieldChange} placeholder="Password" required />
                        <a ><i  onClick={() => {this.setState({showPassword: !this.state.showPassword})}} class={"fa fa-eye" + (this.state.showPassword?"":"-slash")} aria-hidden="true"></i></a>
                    </div>
                    <button type="submit" className="btn btn-primary">{this.state.loading?<i className="fa fa-circle-o-notch fa-spin" />:"Login"}</button>
                    <div className="forgot-pwd">
                        <small><a onClick = {() => {this.props.history.push("/forget-password")}} >Forgot Password</a></small>
                    </div>

                </form>
                {this.state.displayMessage?<div id="loginMsg" style={{background:this.state.background}} className="row inner-wrapper">{this.state.message}</div>:""}

            </main>

        );
    }
}


