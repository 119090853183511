import React from 'react';
// import './WalletBalances.css';
// import NativeTokenList from '../../Components/NativeTokenList/NativeTokenList'
import DataService from "../../DataService/DataService";
import Loader from '../../Components/Loader/Loader';
import noProjectsIcon from '../../Assets/images/no-projects.svg';
const FORWARD=1,BACKWARD=-1,FIRST=-2,LAST=2;
export default class WalletBalances extends React.Component {
    constructor() {
        super();
        this.state = { projectListings: [], isDataLoaded: false,tableLoading:true,transactions:[]
        , pagination: {
            current: 0,
            totalPages:1,
            length:10,
        } };
    }

    loadProjectListings() {
        DataService.getProjectListings().then(res => {
            this.setState({ projectListings: res.data.response.data, isDataLoaded: true });
        });
    }
    changePage = action => {
        if ((action == FORWARD || action == LAST ) && this.state.pagination.current === (this.state.pagination.totalPages - 1)) return;
        if ((action == BACKWARD || action == FIRST) && this.state.pagination.current === 0) return;
        //Can navigate
        this.setState({tableLoading:true})
        let pageToGet = this.state.pagination.current;
        switch (action) {
            case FORWARD:
                pageToGet +=1;
                break;
            case BACKWARD:
                pageToGet -=1;
                break;
            case LAST:
                pageToGet = this.state.pagination.totalPages - 1;
                break;
            case FIRST:
                pageToGet = 0;
                break;
        }
        DataService.getTransactions(pageToGet,10)
        .then (res => {
            if (res.data.statusCode == 200 && res.data.errors.length == 0) {
                this.setState({transactions: res.data.response.data, pagination: {current: pageToGet,length:10,totalPages: parseInt(res.data.response.totalPages)},tableLoading:false});
            }
        });
    }
    projectStyle = {
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        'min-height': '250px',
        justifyContent: 'center',
        alignItems: 'center'

    }
    componentDidMount = () => {
        DataService.getTransactions(0,10)
        .then (res => {
            if (res.data.statusCode == 200 && res.data.errors.length == 0) {
                this.setState({transactions: res.data.response.data, pagination: {current: this.state.pagination.current,length:10,totalPages: parseInt(res.data.response.totalPages)},tableLoading:false});
            }
        });
        DataService.getProjectListings().then(res => {
            let projects = res.data.response.data;
            let promises = [];
            projects.forEach(project => {
                if (project.status.name == "Tokenized" || project.status.name == "Withdraw") {
                    let promise = DataService.getProjectBalance(project.id);
                    promises.push(promise);
                    promise.then(balance => project.balance = balance.data.response.balance == null ? "" : balance.data.response.balance);
                } else {
                    project.balance = "";
                }
            })
            Promise.all(promises).then(res => this.setState({ projectListings: projects, isDataLoaded: true }))
        });
    }
    commaSeparated(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {
        let noProjectsJSX = [<h4 className="mb-20">Native Token Balance</h4>, <div style={this.projectStyle} className="col">
            <img src={noProjectsIcon} style={{ marginTop: "30px" }} width="200px" />
            <span style={{ fontSize: "20px", fontWeight: '500' }}>No projects</span>
            <span>Looks like we have no projects. Lets tokenize one!</span>
            <div className=" button-wrapper w-80 " style={{ marginTop: "40px", marginBottom: "40px" }}>
                <button type="button" onClick={() => { localStorage.removeItem("projectId"); window.location.href="/tokenize";}} style={{ "padding": "10px 25px" }}
                    className=" btn btn-primary w-80">Tokenize New Project</button>
            </div>
        </div>]
        if (this.state.isDataLoaded) {
            return (
                <div id="wallet-wrapper" style={{ width: "100%" }}>
                    <div class="wallet-tab">
                        <div class="center-content">
                            {this.state.projectListings.length == 0 ? noProjectsJSX : [
                                <div class="project-cards">
                                    <h4 class="mb-20">Native Token Balance</h4>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="inner-padding">
                                                {this.state.projectListings.map(project => <div class="heading-wrapper">
                                                    <h5 style={{flex: '1'}}> {project.name}<p>{project.symbol}</p>
                                                    </h5>
                                                    <div style={{flex:'1'}}><strong style={{  fontSize: '15px' }}>{project.balance == "" ? ([this.commaSeparated(project.issueSize) + ` ${project.symbol}`, <i style={{}} class="fa fa-exclamation-circle" aria-hidden="true"></i>]) : `${this.commaSeparated(project.balance.split(/\s+/)[0])}  ${project.symbol}`}</strong></div>
                                                    <div style={{display:'flex',justifyContent:'flex-end',flex:'1'}} class="button-wrapper">

                                                        {project.balance == "" ? <button style={{padding: "5px 15px",minWidth: "0px", width:"unset"}} ref={element => {
                                                                if (element) element.style.setProperty('width','auto','important');
                                                            }} class="float-right inprocess">Processing</button>
                                                            :
                                                            [<button type="button" onClick={() => { localStorage.setItem("projectId", project.id); this.props.history.push("/wallet/transfer") }} class="btn btn-secondary">Withdraw</button>,
                                                            <button type="button" class="btn btn-primary card-overlay" style={{border:'none', position: "relative" }}>Offering</button>]
                                                        }</div>
                                                </div>)}

                                            </div>
                                        </div>
                                    </div>
                                </div>,

                                <div class="document-section">
                                    <h3 style={{color:"#b8b8b8"}}>Proceeds Balance</h3>
                                    <div class="row">

                                        {this.state.projectListings.map(project => <div class="col-md-12 col-lg-6 d-flex">
                                            <div class="inner-padding card-overlay" style={{ position: "relative" }}>
                                                <div class="text-wrapper">
                                                    <h5>{project.name}<p>{project.symbol}</p>
                                                    </h5>
                                                </div>

                                                <div class="budget-wrapper">
                                                    <div class="content-job">
                                                        <div class="text-wrapper">
                                                            <p>Proceeds Balance<strong>0,000,000 USD</strong></p>

                                                        </div>
                                                        <div class="chart-disc">
                                                            <i class="fa fa-square-full"></i>
                                                            <span>USD</span>
                                                            <strong>0,000,000 USD (60%)</strong>
                                                        </div>
                                                        <div class="chart-disc BTC">
                                                            <i class="fa fa-square-full BTC"></i>
                                                            <span>USD</span>
                                                            <strong>0.0000 = 0,000,000 USD (25%)</strong>
                                                        </div>
                                                        <div class="chart-disc BTH">
                                                            <i class="fa fa-square-full BTH"></i>
                                                            <span>USD</span>
                                                            <strong>0.0000 = 0,000,000 USD (15%)</strong>
                                                        </div>
                                                    </div>

                                                    <div style={{ "height": "auto", "width": "190px" }}><img src="images/graph-50.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}

                                    </div>
                                </div>,
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="inner-padding">
                                            <div className="activity-table">
                                                <h4 className="mb-20">Transactions</h4>
                                                {this.state.tableLoading?<Loader/>:[

                                                <table id="dtBasicExample" className="table table-bordered table-hover" cellspacing="5px" width="100%">
                                                    <thead>
                                                    
                                                        <tr>
                                                            <th className="th-sm">ID</th>
                                                            <th className="th-sm">Transaction Hash</th>
                                                            <th className="th-sm">From</th>
                                                            <th className="th-sm">To</th>
                                                            <th className="th-sm">Amount</th>
                                                            <th className="th-sm">Project</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.transactions.map(transaction =>
                                                        <tr>
                                                            <td  >{transaction.id}</td>
                                                            <td style={{wordBreak:"break-word"}}>{transaction.txHash}</td>
                                                            <td style={{wordBreak:"break-word"}}>{transaction.from}</td>
                                                            <td style={{wordBreak:"break-word"}}>{transaction.to}</td>
                                                            <td style={{wordBreak:"break-word"}}>{parseFloat(transaction.amount)}</td>
                                                            <td style={{wordBreak:"break-word"}}>{transaction.project.name}</td>

                                                        </tr>
                                                    )}
                                                    </tbody>
                                                </table>,
                                                <div className="page-num d-flex">
                                                    <span>
                                                        {this.state.pagination.current + 1} of {this.state.pagination.totalPages} pages | {this.state.pagination.length} rows per page
                  </span>
                                                    <ul className="pagination">
                                                        <li className="page-item"><a className="page-link" onClick = {() => this.changePage(FIRST)}>
                                                            {"<<"}</a> </li> <li className="page-item"><a className="page-link"  onClick = {() => this.changePage(BACKWARD)}>
                                                                {"<"}</a> </li> <li className="page-item"><a className="page-link" onClick = {() => this.changePage(FORWARD)}>{">"}</a></li>
                                                        <li className="page-item"><a className="page-link" onClick = {() => this.changePage(LAST)}>{">>"}</a></li>
                                                    </ul>
                                                </div>]}

                                            </div>
                                        </div>
                                    </div>
                                </div>]}
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="container" style={{ width: '30%', textAlign: 'center' }}>
                    <Loader />
                </div>
            );
        }
    }
}
