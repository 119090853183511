import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DataService from '../../DataService/DataService';
import Loader from '../../Components/Loader/Loader';
import ReactTooltip from 'react-tooltip';
import OtpInput from 'react-otp-input';
import { OtpComponent } from 'react-otp-component';

const FORWARD = 1, BACKWARD = -1, FIRST = -2, LAST = 2;
class SecurityManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otpErr: "",
            otpLoading: false,
            otp: "",
            errPass: false,
            errConfirmPass: false,
            labelConfirmPass: '',
            password: '',
            confirmPassword: '',
            valid: false,
            loadingPassword: false,
            passErr: '',
            loginHistory: [],
            pagination: {
                current: 0,
                totalPages: 1,
                length: 10,
            },
            loginHistoryLoading: true
        }
    }
    changePage = action => {
        if ((action == FORWARD || action == LAST) && this.state.pagination.current === (this.state.pagination.totalPages - 1)) return;
        if ((action == BACKWARD || action == FIRST) && this.state.pagination.current === 0) return;
        //Can navigate
        this.setState({ loginHistoryLoading: true })
        let pageToGet = this.state.pagination.current;
        switch (action) {
            case FORWARD:
                pageToGet += 1;
                break;
            case BACKWARD:
                pageToGet -= 1;
                break;
            case LAST:
                pageToGet = this.state.pagination.totalPages - 1;
                break;
            case FIRST:
                pageToGet = 0;
                break;
        }
        DataService.getLoginHistory(this.state.pagination.length, pageToGet)
            .then(res => {
                if (res.data.statusCode == 200 && res.data.errors.length == 0) {
                    this.setState({ loginHistory: res.data.response.data, pagination: { current: pageToGet, length: 10, totalPages: parseInt(res.data.response.totalPages) }, loginHistoryLoading: false });
                }
            });
    }
    componentDidMount = () => {
        DataService.getLoginHistory(this.state.pagination.length, 0)
            .then(
                res => {
                    if (res.data.errors.length == 0) {
                        this.setState({
                            loginHistory: res.data.response.data,
                            pagination: {
                                length: parseInt(res.data.response.perPage),
                                totalPages: parseInt(res.data.response.totalPages),
                                current: 0
                            },
                            loginHistoryLoading: false
                        })
                    }
                }
            ).catch(err => {
                console.log(err);
            })
    }
    requestOTP = () => {
        this.setState({ otpLoading: true, otp: "" }, () => {
            DataService.requestOTP().then(res => {
                if (res.data.response.codeSent == true) {
                    this.setState({ otpLoading: false });
                }
            })
        });
    }
    passwordRegex = /^(((?=.*\d)(?=.*[a-z])(?=.*[A-Z])){3}|((?=.*\d)(?=.*[a-z])(?=.*[!"#$%&'()*+, \-./:;<=>?@ [\\\]^_`}|}~])){3}|((?=.*\d)(?=.*[a-z])(?=.*[\u0080-\uffff])){3}|((?=.*\d)(?=.*[A-Z])(?=.*[!"#$%&'()*+, \-./:;<=>?@ [\\\]^_`}|}~])){3}|((?=.*\d)(?=.*[A-Z])(?=.*[\u0080-\uffff])){3}|((?=.*\d)(?=.*[!"#$%&'()*+, \-./:;<=>?@ [\\\]^_`}|}~])(?=.*[\u0080-\uffff])){3}|((?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+, \-./:;<=>?@ [\\\]^_`}|}~])){3}|((?=.*[a-z])(?=.*[A-Z])(?=.*[\u0080-\uffff])){3}|((?=.*[a-z])(?=.*[!"#$%&'()*+, \-./:;<=>?@ [\\\]^_`}|}~])(?=.*[\u0080-\uffff])){3}|((?=.*[A-Z])(?=.*[!"#$%&'()*+, \-./:;<=>?@ [\\\]^_`}|}~])(?=.*[\u0080-\uffff])){3}).{8,}$/g
    onFieldChange = e => {

        let newObj = {};
        newObj[e.target.id] = e.target.value;
        newObj.valid = true;
        newObj.passErr = "";
        this.setState(newObj, () => {
            if (this.state.password.match(this.passwordRegex) == null) {
                this.setState({ valid: false, passwordErr: "Password should be atleast of 8 characters and should contain atleast 1 numeric value and one symbol" });
            } else {
                this.setState({ passwordErr: "" });
            }

            if (this.state.confirmPassword != this.state.password) {
                this.setState({ valid: false, confirmPasswordErr: "Passwords dont match" });
            } else {
                this.setState({ confirmPasswordErr: "" });
            }
        });
    }
    changePassword = e => {
        e.preventDefault();
        if (this.state.valid) {
            this.setState({ loadingPassword: true }, () => {
                DataService.changePassword(this.state.oldPassword, this.state.password, this.state.confirmPassword)
                    .then(res => {
                        if (res.data.statusCode == 200) {
                            if (res.data.errors.length != 0 || !res.data.response.success) {
                                this.setState({ loadingPassword: false, passErr: "Errors: " + res.data.errors.map(v => v.message).toString() });
                            } else {
                                this.setState({ loadingPassword: false });
                                this.props.login(this.state.password);
                                document.getElementById("changePasswordModal").click();
                            }
                        } else {
                            this.setState({ loadingPassword: false, passErr: `Error Code: ${res.data.statusCode}` });

                        }
                    }).catch(err => this.setState({ loadingPassword: false, passErr: `Unknown Error: ${err.toString()}` }));
            })
        }
    }
    handleOtp = otp => {
        this.setState({ otp: otp, otpErr: "", otpLoading: otp.length == 6 }, () => {
            if (this.state.otp.length == 6) {

                DataService.verifyOtp(this.state.otp)
                    .then(res => {

                        if (res.data.statusCode == 200) {
                            if (res.data.errors.length != 0) {
                                this.setState({ otpLoading: false, otp: "", otpErr: res.data.errors.map(v => v.message).toString() });
                            } else {
                                //Close the Modal
                                document.getElementById("otpCloseModal").click();
                                this.props.updateUser();
                            }
                        } else {
                            this.setState({ otp: "", otpErr: "Error Code: " + res.data.statusCode, otpLoading: false });
                        }

                    }).catch(err => this.setState({ otp: "", otpErr: "Unhandled Error: " + err.toString(), otpLoading: false }))
            }
        });
    }
    render() {
        return (<div id="security-section" style={{ "width": "100%" }}>  <div className="security-section">
            <div className="modal fade manage-modal" id="manage-phone-modal" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="exampleModalLongTitle">Manage Phone Number</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="x-icon" aria-hidden="true">&times;</span></button>
                            <div className="modal-content">
                                <span>+***********86</span>
                                <p>Added 2 months ago</p>
                                <strong>Verified</strong>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary">Change Phone Number</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade manage-modal" id="change-pass-modal" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="exampleModalLongTitle">Change Password</h5>
                            <button type="button" className="close" id="changePasswordModal" data-dismiss="modal" aria-label="Close">
                                <span className="x-icon" aria-hidden="true">&times;</span></button>
                            <form style={{ border: 'none', marginTop: '10px' }} className="main-content login-form inner-wrapper" onSubmit={this.state.loadingPassword ? "" : this.changePassword}>
                                <div className="form-group">
                                    <label for="newpassword">Old Password</label>
                                    <input type={this.state.showOldPassword ? "text" : "password"} className="form-control" onChange={e => { this.setState({ oldPassword: e.target.value }) }} id="password" aria-describedby="emailHelp"
                                        placeholder="Old Password" required />
                                    <a><i onClick={() => { this.setState({ showOldPassword: !this.state.showOldPassword }) }} class={"fa fa-eye" + (this.state.showPassword ? "" : "-slash")} aria-hidden="true"></i></a>

                                </div>
                                <div className="form-group">
                                    <label for="newpassword">New Password</label>
                                    <input type={this.state.showPassword ? "text" : "password"} className="form-control" onChange={this.onFieldChange} id="password" aria-describedby="emailHelp"
                                        placeholder="New Password" required />
                                    <a><i onClick={() => { this.setState({ showPassword: !this.state.showPassword }) }} class={"fa fa-eye" + (this.state.showPassword ? "" : "-slash")} aria-hidden="true"></i></a>

                                    <span style={{ color: "red", fontSize: "10px", textAlign: "left" }}>{this.state.passwordErr}</span>
                                </div>
                                <div className="form-group">
                                    <label for="confirmpassword">Confirm Password</label>
                                    <input type={this.state.showPassword ? "text" : "password"} required className="form-control" onChange={this.onFieldChange} id="confirmPassword" placeholder="Confirm Password" />
                                    <a><i onClick={() => { this.setState({ showConfirmPassword: !this.state.showConfirmPassword }) }} class={"fa fa-eye" + (this.state.showConfirmPassword ? "" : "-slash")} aria-hidden="true"></i></a>
                                    <span style={{ color: "red", fontSize: "10px", textAlign: "left" }}>{this.state.confirmPasswordErr}</span>


                                </div>
                                <button type="submit" className="btn btn-primary">{this.state.loadingPassword ? <i className="fa fa-circle-o-notch fa-spin" /> : "Change Password"}</button>
                            </form>
                            <div className="mt-0 w-100" style={{ "background": "red", "color": "white", "padding": "20px", "font-size": "14px", opacity: (this.state.passErr == "" ? "0" : "1"), transition: "all 0.25s" }}>
                                {this.state.passErr}
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade manage-modal" id="otp-verification-modal" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="exampleModalLongTitle">Verify Phone Number</h5>
                            <button type="button" className="close" id="otpCloseModal" data-dismiss="modal" aria-label="Close">
                                <span className="x-icon" aria-hidden="true">&times;</span></button>
                            <div className="card mt-3 tab-card">
                                <div className="tab-content" id="myTabContent">
                                    {this.state.otpLoading ? <Loader /> :
                                        <div className="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="one-tab">
                                            <p className="card-text">{this.state.requestMsg}</p>
                                            <div className="partition" style={{ flexDirection: "column" }}>
                                                <label for="verify">Verification Code</label>

                                            </div>
                                            {this.state.otpLoading?"":
                                            <div style={{marginTop:"20px"}} className="otpContainer">
                                                <OtpComponent
                                                    size={6}
                                                    border="2px solid black"
                                                    style={{ padding: "0px" }}
                                                    completeOtpEntered={this.handleOtp}
                                                />
                                            </div>}
                                        </div>
                                    }
                                </div>

                            </div>

                        </div>
                        <div className="mt-0" style={{ "background": "red", "color": "white", "padding": "20px", "font-size": "14px", opacity: (this.state.otpErr == "" ? "0" : "1"), transition: "all 0.25s" }}>
                            {this.state.otpErr}
                        </div>

                    </div>

                </div>
            </div>
            <div class="center-content w-100">

                <div className="heading-card">
                    <h4 className="mb-30">Settings &nbsp;{">"}&nbsp; Security</h4>
                    <div className="row mb-20">
                        <div className="col-md-6">
                            <div className="inner-padding">

                                <h5>Phone Numbers</h5>
                                <div style={{ flexDirection: 'column', alignItems: "flex-start" }} className="add-account">
                                    <p style={{ flex: 1, maxWidth: '100%' }}><strong>{this.props.user.mobileNumber ? ("*".repeat(this.props.user.mobileNumber.length - 2) + this.props.user.mobileNumber.slice(-2)) : "No Phone Number"}</strong>Keep your primary phone number up to date.</p>
                                    <p style={{ flex: 1, maxWidth: '100%' }}>Phone numbers can only be changed by your administrator. If you would like to change your phone number please consider making a request on support page</p>
                                    <button style={{ marginLeft: "auto" }} type="button" className="btn btn-primary" onClick={() => { this.props.history.push("/support") }}>Go to Support</button>


                                </div>

                                <div className="code-section">
                                    <h4 className="mb-20">2 Steps Verification</h4>
                                    <div className="partition mb-10">
                                        <div className="text-wrap">
                                            <h5>SMS Authentication Code</h5>
                                            <p>OTP sent to your Phone</p>
                                        </div>

                                        {this.props.user.isMobileVerified == 1 ? <i style={{ color: "#0099a1", fontSize: "34px", marginBottom: "25px" }} class="fa fa-check-circle-o pr-4" aria-hidden="true"></i> :
                                            (this.props.user.mobileNumber ? <button type="button" onClick={this.requestOTP} data-target="#otp-verification-modal"
                                                data-toggle="modal" className="btn btn-primary">Enable</button> : <span style={{ fontSize: "12px" }}>No Phone Number<i className="fa fa-warning" aria-hidden="true" style={{ color: "red" }}></i></span>)}
                                    </div>
                                    <div className="partition">
                                        <div className="text-wrap">
                                            <h5>Google Authentication Code</h5>
                                        </div>
                                        {this.props.user.isGAuthActive == 1 ? <i style={{ color: "#0099a1", fontSize: "34px" }} class="fa fa-check-circle-o pr-4" aria-hidden="true"></i> :
                                            <span data-for={this.props.user.isMobileVerified == 0 && "main"} data-tip={this.props.user.isMobileVerified == 0 && "Please verify phone first"} ><button disabled={this.props.user.isMobileVerified == 0} type="button" onClick={() => { this.props.history.push("/security/gauth") }} className="btn btn-secondary" >Enable</button></span>}
                                    </div>
                                    <ReactTooltip id="main" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="inner-padding">

                                <div className="add-account card-overlay" style={{ position: "relative" }}>
                                    <h5>Anti Phishing Code<p>By setting up an Anti Phishing code, you will be able to tell
                      if your notification emails are from Fasste or Phishing attempts.</p>
                                    </h5>
                                    <button type="button" className="btn btn-primary">Change</button>
                                </div>

                                <div className="add-account">
                                    <h5>Login Password<p></p>
                                    </h5>
                                    <button type="button" data-target="#change-pass-modal"
                                        data-toggle="modal" className="btn btn-primary">Change</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="inner-padding">
                            <div className="activity-table">
                                <h4 className="mb-20">Account Activity</h4>
                                {this.state.loginHistoryLoading ? <Loader /> :
                                    <table id="dtBasicExample" className="table table-bordered table-hover" cellspacing="5px" width="100%">
                                        <thead>
                                            <tr>
                                                <th className="th-sm">Activity</th>
                                                <th className="th-sm">Source</th>
                                                <th className="th-sm">IP Address</th>
                                                <th className="th-sm">Location</th>
                                                <th className="th-sm">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.loginHistory.map(history =>
                                                <tr>
                                                    <td>Last Login</td>
                                                    <td>web</td>
                                                    <td>{history.ip}</td>
                                                    <td>{history.location}</td>
                                                    <td>{(new Date(history.loggedOn)).toString()}</td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                }
                                <div className="page-num d-flex">
                                    <span>
                                        {this.state.pagination.current + 1} of {this.state.pagination.totalPages} pages | {this.state.pagination.length} rows per page
                  </span>
                                    <ul className="pagination">
                                        <li className="page-item"><a className="page-link" onClick={() => this.changePage(FIRST)}>
                                            {"<<"}</a> </li> <li className="page-item"><a className="page-link" onClick={() => this.changePage(BACKWARD)}>
                                                {"<"}</a> </li> <li className="page-item"><a className="page-link" onClick={() => this.changePage(FORWARD)}>{">"}</a></li>
                                        <li className="page-item"><a className="page-link" onClick={() => this.changePage(LAST)}>{">>"}</a></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div></div> </div>);
    }
}

export default SecurityManagement;