import React from 'react';
//import './InputField.css';

export default class InputField extends React.Component {
    render() {
        return (
            <div className="InputMain">
                <label>
                    {this.props.label}
                </label>
                <input type={this.props.type} value={this.props.value} disabled={(this.props.disabled? "disabled":"")} id={this.props.id} placeholder={this.props.placeholder} onChange={this.props.onChange}/>
                <label>
                    {this.props.err?this.props.errLabel:''}
                </label>
            </div>
        );

    }
}