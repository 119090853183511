import axiosMain from "axios";
//Declare All Constant URL
const BASE_URL = "https://api.fasset.com/api";
const LOGIN = "/v1/users/login";
const REGISTER = "/v1/users/register";
const PROJECTS = "/v1/projects/all/";
const SECTORS = "/v1/lookups/sector/all/";
const PROJECT_DETAIL = `/v1/projects/:id/detail`;
const DEPARTMENT = '/v1/lookups/department/all/';
const DESIGNATION = '/v1/lookups/designation/all/';
const FORGOT_PASSWORD = "/v1/users/forgot-password";
const RESET_PASSWORD = "/v1/users/reset-password";
const VERIFY_PASSWORD_TOKEN = "/v1/users/reset-password/verify/token/{token}/email/{email}";
const CREATE_PROJECT = "/v1/projects/create";
const UPDATE_PROJECT = "/v1/projects/update";
const GET_PROJECT_DOCUMENTS = "/v1/projects/{projectId}/documents";
const CHECK_PROJECT_SYMBOL = "/v1/projects/availability/symbol/{symbol}/{projectId}";
const GET_USER_DOCUMENT_CONFIGURATIONS = "/v1/users/documents-config-list";
const GET_PROJECT_DOCUMENT_CONFIG = "/v1/projects/documents-config-list";
const UPDATE_PROFILE = "/v1/users/edit-profile";
const UPLOAD_DOCUMENT = "​/v1​/projects​/documents/upload";
const GET_USER_DOCUMENTS = "/v1/users/documents";
const UPLOAD_USER_DOCUMENT = "/v1/users/documents/upload";
const VERIFY_REGISTRATION_TOKEN = "/v1/users/register/verify/token/{token}/email/{email}";
const GET_USER = "/v1/users/info";
const RESET_PASSWORD_FORCE = "/v1/users/force-reset-password";
const CHECK_PROJECT_SYMBOL_EDIT = "/v1/projects/availability/symbol/{symbol}/{projectId}";
const GET_PROJECT_BALANCE = "/v1/projects/{projectId}/balance";
const REQUEST_OTP = "/v1/users/otp/sms";
const GET_GAUTH_QRCODE = "/v1/users/otp/qrcode";
const SETUP_GAUTH = "/v1/users/otp/gauth/setup";
const VERIFY_OTP = "/v1/users/otp/verify";
const CHANGE_PASSWORD = "/v1/users/change-password";
const WITHDRAW = "/v1/projects/withdraw";
const WITHDRAW_VERIFY = "/v1/projects/withdraw/token/{token}";
const GET_TRANSACTIONS = "/v1/projects/transactions/limit/{limit}/page/{page}";
const GET_LOGIN_HISTORY = "/v1/users/activity/limit/{limit}/page/{page}";
//Create Instance of Axios with default settings
const axios = axiosMain.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "accept": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`
  }
});


axios.interceptors.request.use(config => {
  console.log(config);
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});
////////////////// GET /////////////////

const getTransactions = (page,limit) => {
  return axios.get(GET_TRANSACTIONS.replace("{limit}",limit).replace("{page}",page));
}

const resetPasswordForce = (payLoad, token) => {
  return axiosMain.post(BASE_URL + RESET_PASSWORD_FORCE, payLoad, {
    headers: {
      "Content-Type": "application/json",
      "accept": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
}

const withdraw = payload => {
  return axios.post(WITHDRAW,payload);
}
const changePassword = (old, newPass, newPassconfirm) => {
  let payload = {
    "currentPassword": old,
    "newPassword": newPass,
    "confirmPassword": newPassconfirm
  }
  return axios.post(CHANGE_PASSWORD,payload);
}
const verifyOtp = otp => {
  return axios.post(VERIFY_OTP, { "code": otp });
}

const setupGAUTH = (password, gauthcode, otp) => {
  let payload = {
    gAuthCode: gauthcode,
    loginPassword: password,
    smsCode: otp
  };
  return axios.post(SETUP_GAUTH, payload);
}

const getGAuthQRCode = () => {
  return axios.get(GET_GAUTH_QRCODE);
}
const requestOTP = () => {
  return axios.get(REQUEST_OTP);
}

const getLoginHistory = (limit,page) => {
  return axios.get(GET_LOGIN_HISTORY.replace("{limit}",limit).replace("{page}",page));
}
const getAllSectors = () => {
  let url = SECTORS + 'limit/10/page/0';
  return axios.get(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
  });
}

const getProjectBalance = id => {
  return axios.get(GET_PROJECT_BALANCE.replace("{projectId}", id));
}

const checkIfProjectSymbolAvailable = (symbol, projectId) => {
  let url = CHECK_PROJECT_SYMBOL.replace("{symbol}", symbol).replace("{projectId}", projectId);
  return axios.get(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
  });
}


const verifyUser = (token, email) => {
  return axios.get(VERIFY_REGISTRATION_TOKEN.replace("{token}", token).replace("{email}", email));
}

const getProjectByProjectId = (projectId) => {
  return axios.get("https://api.fasset.com/api/v1/projects/" + projectId + "/detail ")
};
// const getProjectListings = () => {
//   let url = PROJECTS + "limit/10/page/0";
//   return axios.get(url, {
//     headers : {
//       'Authorization' : `Bearer ${localStorage.getItem("token")}`
//     }
//   });
// }


// const getProjectByProjectId = (projectId) => {
//   let url = PROJECT_DETAIL.replace(':id',projectId);
//   return axios.get(url, {
//     headers : {
//       'Authorization' : `Bearer ${localStorage.getItem("token")}`
//     }
//   });
// }


const getDocumentsConfigList = () => {
  let url = GET_PROJECT_DOCUMENT_CONFIG
  return axios.get(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
  });
}


const getProjectUploadedDocuments = (projectId) => {
  let url = GET_PROJECT_DOCUMENTS.replace('{projectId}', projectId);
  return axios.get(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
  });
}

const checkProjectSymbolEdit = (symbol, projectId) => {
  return axios.get(CHECK_PROJECT_SYMBOL_EDIT.replace("{symbol}", symbol).replace("{projectId}", projectId));
}
///////////////// POST /////////////////

const createProject = body => {
  return axios.post(CREATE_PROJECT, body);
}

const uploadDocument = (body, setProgress) => {
  return axios.post("/v1/projects/documents/upload", body, {
    onUploadProgress: setProgress
  });
}

//All data methods
const getProjects = (limit = 10, page = 0) => {
  let url = PROJECTS + `limit/${limit}/page/${page}`;
  return axios.get(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
  });
}
const getProjectListings = getProjects;

const getSectors = (limit = 1000, page = 0) => {
  let url = SECTORS + `limit/${limit}/page/${page}`;
  return axios.get(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
  });
}

const getDesignations = (limit = 1000, page = 0) => {
  let url = DESIGNATION + `limit/${limit}/page/${page}`;
  return axios.get(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
  });
}
const forgotPassword = body => {
  return axios.post(FORGOT_PASSWORD, body);
}
const getDepartments = (limit = 1000, page = 0) => {
  let url = DEPARTMENT + `limit/${limit}/page/${page}`;
  return axios.get(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
  });
}
const fetchData = () => {
  return axios.get("https://jsonplaceholder.typicode.com/todos/1", {

  })
};
const getProject = id => {
  let url = PROJECT_DETAIL.replace(':id', id);
  console.log(BASE_URL + url);
  return axios.get(url);
}
const uploadDocumentUser = (body, setProgress) => {
  let formData = new FormData();
  Object.keys(body).forEach(key => {
    formData.append(key, body[key]);
  })
  return axios.post(UPLOAD_USER_DOCUMENT, formData, {
    onUploadProgress: setProgress
  });
}

const getUserDocuments = () => {
  return axios.get(GET_USER_DOCUMENTS);
}
const getProjectDocuments = id => {
  return axios.get(GET_PROJECT_DOCUMENTS.replace("{projectId}", id));
}
const getUserDocumentConfig = () => {
  return axios.get(GET_USER_DOCUMENT_CONFIGURATIONS);
}
const register = body => {
  return axios.post(REGISTER, body);

}

const getUser = () => {
  return axios.get(GET_USER);
}
const withdrawVerify = token => {
  return axios.get(WITHDRAW_VERIFY.replace("{token}",token));
}

const doLogin = body => {
  //Init Promise
  let toR = new Promise((accept,reject) => {
    //Get Location
    axiosMain.get("https://freegeoip.app/json/").then(json => {
      accept(axios.post(LOGIN,body,{
        headers: {
          ip:json.data.ip,
          location: `${json.data.city}, ${json.data.country_code}`
        }
      }));
    }).catch (err => accept(axios.post(LOGIN,body,{
      headers: {
        ip:"Cant Determine",
        location: `Cant Determine`
      }
    })));
  })

  return toR;
}
const resetPassword = body => {
  return axios.post(RESET_PASSWORD, body);
}
const verifyPasswordToken = (token, email) => {
  let url = VERIFY_PASSWORD_TOKEN.replace("{email}", email).replace("{token}", token);
  return axios.get(url);
}
const updateProfile = body => {
  return axios.post(UPDATE_PROFILE, body);
}

const updateProject = body => {
  return axios.post(UPDATE_PROJECT, body);
}
export default {
  getAllSectors,
  getProjectListings,
  createProject,
  getDocumentsConfigList,
  getProjectUploadedDocuments,
  uploadDocument,
  getProjectByProjectId,
  fetchData,
  doLogin,
  getProjects,
  getSectors,
  getProject,
  forgotPassword,
  resetPassword,
  verifyPasswordToken,
  updateProject,
  getProjectDocuments,
  updateProfile,
  getDepartments,
  getDesignations,
  getUserDocumentConfig,
  uploadDocumentUser,
  getUserDocuments,
  getUser,
  verifyUser,
  resetPasswordForce,
  checkIfProjectSymbolAvailable,
  checkProjectSymbolEdit,
  getProjectBalance,
  requestOTP,
  getGAuthQRCode,
  setupGAUTH,
  verifyOtp,
  changePassword,
  withdraw,
  withdrawVerify,
  getTransactions,
  getLoginHistory
}
