import React from 'react';
import './AdminPanel.css';
import placeimg_200_200_grayscale_any from '../../images/placeimg_200_200_grayscale_any.jpg';
import FileUploader3 from '../../Components/FileUploader3/FileUploader3';
import DataService from "../../DataService/DataService";
import Loader from '../../Components/Loader/Loader';
import docPic from "../../images/file_check.svg";


export default class AdminPanel extends React.Component {
  constructor() {
    super();
    this.state = { documentConfigs: [], documents: [], isDataLoaded: false, defaultIcons: {} };
  }

  componentDidMount() {
    Promise.all(
      [DataService.getUserDocumentConfig(),
      DataService.getUserDocuments()
      ]).then(responses => {
        this.setState({ documentConfigs: responses[0].data.response, documents: responses[1].data.response, isDataLoaded: true });
      });
  }

  render() {
    if (this.state.isDataLoaded) {
      return (
        <div class="center-content project-wrapper">

          <div class="job-details">
            <h4 class="mb-20">Sub Accounts</h4>
            <div class="row card-overlay" style={{position:"relative"}}>
              <div class="col-md-12 col-lg-8">
                {/* <!-- Job Section --> */}
                <div class="job-wrapper">
                  <div class="row">
                    <div class="col-md-6" style={{ marginBottom: '20px' }}>
                      <div class="inner-padding">
                        <div class="job-card" style={{ textAlign: 'center' }}>
                          <img src="images/user-img.png" alt="User image" style={{ width: '40px', height: '40px', marginBottom: '10px' }} />
                          <div style={{ margin: '0px', fontSize: '14px' }}><strong>John Doe</strong></div>
                          <div style={{ color: 'black', margin: '0px' }}><a href="#">jd@abcd.com</a></div>
                          <div style={{ marginTop: '15px' }}>
                            <strong style={{ fontSize: '15px' }}>
                              Department:<label style={{ fontSize: '15px', fontWeight: '300', color: '#999' }}> &nbsp; Finance</label>
                            </strong>
                          </div>
                          <div style={{ marginTop: '-5px' }}>
                            <strong style={{ fontSize: '15px' }}>
                              Designation:<label style={{ fontSize: '15px', fontWeight: '300', color: '#999' }}> &nbsp; Manager</label>
                            </strong>
                          </div>
                          {/* <!-- Button Group --> */}
                          <div class="button-wrapper text-center" style={{ marginTop: '5px' }}>
                            <select class="btn-secondary" style={{ height: '30px' }}>Edit
                            <option selected="">Edit</option>
                              <option>Admin</option>
                              <option>Auditor</option>
                            </select>
                            <button type="submit" class="btn btn-primary" style={{ height: '30px' }}>Remove</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6" style={{ marginBottom: '20px' }}>
                      <div class="inner-padding">
                        <div class="job-card" style={{ textAlign: 'center' }}>
                          <img src="images/user-img.png" alt="User image" style={{ width: '40px', height: '40px', marginBottom: '10px' }} />
                          <div style={{ margin: '0px', fontSize: '14px' }}><strong>John Doe</strong></div>
                          <div style={{ color: 'black', margin: '0px' }}><a href="#">jd@abcd.com</a></div>
                          <div style={{ marginTop: '15px' }}>
                            <strong style={{ fontSize: '15px' }}>
                              Department:<label style={{ fontSize: '15px', fontWeight: '300', color: '#999' }}> &nbsp; Finance</label>
                            </strong>
                          </div>
                          <div style={{ marginTop: '-5px' }}>
                            <strong style={{ fontSize: '15px' }}>
                              Designation:<label style={{ fontSize: '15px', fontWeight: '300', color: '#999' }}> &nbsp; Manager</label>
                            </strong>
                          </div>
                          {/* <!-- Button Group --> */}
                          <div class="button-wrapper text-center" style={{ marginTop: '5px' }}>
                            <select class="btn-secondary" style={{ height: '30px' }}>Edit
                            <option selected="">Edit</option>
                              <option>Admin</option>
                              <option>Auditor</option>
                            </select>
                            <button type="submit" class="btn btn-primary" style={{ height: '30px' }}>Remove</button>
                          </div>
                        </div></div>
                    </div>
                    <div class="col-md-6" style={{ marginBottom: '20px' }}>
                      <div class="inner-padding">
                        <div class="job-card" style={{ textAlign: 'center' }}>
                          <img src="images/user-img.png" alt="User image" style={{ width: '40px', height: '40px', marginBottom: '10px' }} />
                          <div style={{ margin: '0px', fontSize: '14px' }}><strong>John Doe</strong></div>
                          <div style={{ color: 'black', margin: '0px' }}><a href="#">jd@abcd.com</a></div>
                          <div style={{ marginTop: '15px' }}>
                            <strong style={{ fontSize: '15px' }}>
                              Department:<label style={{ fontSize: '15px', fontWeight: '300', color: '#999' }}> &nbsp; Finance</label>
                            </strong>
                          </div>
                          <div style={{ marginTop: '-5px' }}>
                            <strong style={{ fontSize: '15px' }}>
                              Designation:<label style={{ fontSize: '15px', fontWeight: '300', color: '#999' }}> &nbsp; Manager</label>
                            </strong>
                          </div>
                          {/* <!-- Button Group --> */}
                          <div class="button-wrapper text-center" style={{ marginTop: '5px' }}>
                            <select class="btn-secondary" style={{ height: '30px' }}>Edit
                            <option selected="">Edit</option>
                              <option>Admin</option>
                              <option>Auditor</option>
                            </select>
                            <button type="submit" class="btn btn-primary" style={{ height: '30px' }}>Remove</button>
                          </div>
                        </div></div>
                    </div>
                    <div class="col-md-6" style={{ marginBottom: '20px' }}>
                      <div class="inner-padding">
                        <div class="job-card" style={{ textAlign: 'center' }}>
                          <img src="images/user-img.png" alt="User image" style={{ width: '40px', height: '40px', marginBottom: '10px' }} />
                          <div style={{ margin: '0px', fontSize: '14px' }}><strong>John Doe</strong></div>
                          <div style={{ color: 'black', margin: '0px' }}><a href="#">jd@abcd.com</a></div>
                          <div style={{ marginTop: '15px' }}>
                            <strong style={{ fontSize: '15px' }}>
                              Department:<label style={{ fontSize: '15px', fontWeight: '300', color: '#999' }}> &nbsp; Finance</label>
                            </strong>
                          </div>
                          <div style={{ marginTop: '-5px' }}>
                            <strong style={{ fontSize: '15px' }}>
                              Designation:<label style={{ fontSize: '15px', fontWeight: '300', color: '#999' }}> &nbsp; Manager</label>
                            </strong>
                          </div>
                          {/* <!-- Button Group --> */}
                          <div class="button-wrapper text-center" style={{ marginTop: '5px' }}>
                            <select class="btn-secondary" style={{ height: '30px' }}>Edit
                            <option selected="">Edit</option>
                              <option>Admin</option>
                              <option>Auditor</option>
                            </select>
                            <button type="submit" class="btn btn-primary" style={{ height: '30px' }}>Remove</button>
                          </div>
                        </div></div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md-12 col-lg-4 d-flex flex-column">
                <div class="inner-padding content-height">
                  <h5 class="text-center bold">Add New Sub Account</h5>
                  {/* <!-- form to be added --> */}
                  <div class="form-wrapper">
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label for="email">Email Address</label>
                        <input type="text" class="form-control" id="email" placeholder="jd@abcd.com" />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" id="name" placeholder="John Doe" />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="dept">Department</label>
                        <select id="dept" class="form-control">
                          <option selected="">Choose Department</option>
                          <option>Front End Developer</option>
                          <option>Back End Developer</option>
                          <option>React Native Developer</option>

                        </select>
                      </div>
                      <div class="form-group col-md-12">
                        <label for="designation">Designation</label>
                        <select id="designation" class="form-control">
                          <option selected="">Choose Designation</option>
                          <option>Manager</option>
                          <option>Developer</option>
                          <option>Designer</option>
                        </select>
                      </div>
                    </div>
                    {/* <!-- Button Group --> */}
                    <div class="button-wrapper text-right">
                      <button type="submit" class="btn btn-primary">Add</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Documentation Section --> */}
          <div class="document-section">
            <h4 class="mb-20">Documentation</h4>
            <div class="row">
              {
                this.state.documentConfigs.map(document => {
                  return (
                    <FileUploader3
                      id={document.id}
                      documentConfig={document}
                      document={this.state.documents.find(d => document.id === d.documentConfigurationId)}
                    />
                  )
                })
              }
              {/* <!-- Button Group --> */}
              <div class="col-12 button-wrapper d-flex justify-content-end">
                <button type="submit" class="btn btn-secondary">Update</button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="container" style={{ width: '30%', textAlign: 'center' }}>
          <Loader />
        </div>
      );
    }
  }
}
