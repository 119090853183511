import React from 'react';
import {Redirect} from 'react-router';
//import './ProjectStatusList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBell,faSortDown, faPlay,faTimes, faExternalLinkAlt  } from '@fortawesome/free-solid-svg-icons'
import Button from '../../Components/Button/Button';
export default class ProjectStatusList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        }
    }
    render() {
        
        return (
            <div className={"PSLMain " + (this.props.status.toUpperCase() == "INPROGRESS"? "processing": this.props.status)}>
                <div className = "left">
                    <span className="PSLcategory">{this.props.category}</span>
                    <span className="PSLtitle">{this.props.title}</span>
                    <span className="PSLcode">{this.props.code}</span>
                    <span className="PSLid">{this.props.id}</span>
                </div>
                <div className="right">
                        <span className="PSLstatus">{this.props.status.toUpperCase()}</span>
                        <span className="PSLview"><a href="#">VIEW IN BLOCKCHAIN<FontAwesomeIcon icon="external-link-alt"/></a></span>
                </div>
                <div className="PSLOverlay">
                    <Button
                        label="View Project"
                        onClick = {() => this.setState({redirect:true})}
                        type="filled"
                        color="primary"
                    />
                    {this.state.redirect? <Redirect to={`/dashboard/myprojects/detail/${this.props.id}`}/>:''}
                </div>
            </div>
        )
    }
}