import React, { Component } from 'react';
import './DocumentVerificationScreen.css';
import Button from '../../Components/Button/Button';
import FileUploader2 from '../../Components/FileUploader2/FileUploader2';
import DataService from '../../DataService/DataService';
import { Redirect } from 'react-router';
import Loader from '../../Components/Loader/Loader';
class DocumentVerificationScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documentConfigs: [],
            documents: [],
            loading: true
        }
    }
    componentDidMount() {
        Promise.all(
            [DataService.getUserDocumentConfig(),
            DataService.getUserDocuments()
            ]).then(responses => {
                this.setState({ documentConfigs: responses[0].data.response, documents: responses[1].data.response, loading: false });
            });
    }
    render() {
        if (this.state.loading) return <Loader/>
        if (this.state.redirect) return <Redirect to={this.state.redirectURL} />
        var uploadFieldJSX =
            this.state.documentConfigs.map(document =>
                <div class="row upload-row">
                    <div class="col-sm-5">
                        <p>{document.title}</p>
                    </div>
                    <div class="col-sm-6 upload-wrapper">
                    <FileUploader2
                        id={document.id}
                        documentConfig={document}
                        document={this.state.documents.find(d => document.id == d.documentConfigurationId)}
                    />
                    </div>
                    <div class="col-sm-1 d-none d-sm-block">
                        <div class=" custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck1" />
                            <label class="custom-control-label" for="customCheck1"></label>
                        </div>
                    </div>
                </div>
                
            );

        return (
            <div id="doc-verification" class="doc-verification verification-account-final-step">
                <main class="main-content">
                    <h2>Finish Setting Up Your Account</h2>

                    <div class="inner-wrapper">



                        <div class="heading-wrapper text-center">
                            <h3>Corporate Verification</h3>
                            <strong>Please upload documentaion for the purpose of KYC </strong>
                        </div>

                        <div class="upload-content">
                            <small class="d-none d-sm-block mr-3">N/A</small>
                            {uploadFieldJSX}

                        </div>
                        <span>
                            If any documentaion are not available, please skip them and click submit to proceed.
        </span>
                        <div class="button-wrapper">
                            <button type="submit" onClick={() => this.props.history.push("/")} class="btn btn-secondary">Save and Skip For Now</button>
                            <button type="submit"  onClick={() => this.props.history.push("/")} class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default DocumentVerificationScreen;