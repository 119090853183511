import React, { Component } from 'react';
import DataService from '../../DataService/DataService';
import Loader from '../../Components/Loader/Loader';
import noProjectsIcon from '../../Assets/images/no-projects.svg';
import GAuthLogo from '../../images/gauth.svg';
const { keccak256 } = require('js-sha3');


class Withdraw extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            otpLoading: false,
            errors: {
            },
            amount: "",
            rcv: "",
            otpMethod: "GAuth",
            requestMsg: <img style={{paddingTop:"10px",marginTop:"10px"}}src={GAuthLogo} width="70px"/>,
            withdrawErr: '',
        }
    }
    requestOtp = () => {
        this.setState({ requestMsg: "Please input the 6 digit code sent to you on your registered mobile number.", otp: "", otpLoading: true, otpMethod: "sms" }, () => {
            DataService.requestOTP().then(res => {
                if (res.data.response.codeSent == true) {
                } else {
                    document.getElementById("otpClose").click();
                }
                this.setState({ otpLoading: false });

            })
        });
    }
    handleOtp = e => {
        this.setState({ otp: e.target.value, otpLoading: e.target.value.length == 6 }, prevState => {
            if (this.state.otp.length != 6) return;
            let payload = {
                "to": this.state.rcv,
                "amount": this.state.amount,
                "projectId": this.state.project.id
            }
            if (this.state.otpMethod == "GAuth") {
                //Handle GAuth
                payload["gauthOTP"] = this.state.otp;
            } else {
                //Handle Otp
                payload["smsOTPCode"] = this.state.otp;
            }
            DataService.withdraw(payload)
                .then(res => {

                    if (res.data.statusCode == 200) {
                        if (res.data.errors.length != 0) {
                            this.setState({ otpLoading: false, withdrawErr: res.data.errors.map(v => v.message).toString() });
                        } else if (res.data.response.sentWithdrawVerificationEmail) {
                            //Withdraw success
                            this.setState({ otpLoading: false });
                            document.getElementById("otpClose").click();
                            document.getElementById("emailModal").click();
                        }
                    } else {
                        this.setState({ otpLoading: false, withdrawErr: res.data.statusCode });
                    }
                }).catch(err => this.setState({ otpLoading: false, withdrawErr: err.toString() }));

        })
    }

    handleGAuth = () => {
        this.setState({ requestMsg: <img style={{paddingTop:"10px",marginTop:"10px"}} src={GAuthLogo} width="70px"/>, otp: "", otpMethod: "GAuth", otpLoading: false });
    }
    componentDidMount = () => {
        if (localStorage.getItem("projectId") == null) {
            this.props.history.goBack();
        } else {
            DataService.getProject(localStorage.getItem("projectId")).then(res => {
                try {
                    let project = res.data.response;
                    DataService.getProjectBalance(project.id).then(res => {
                        project.balance = res.data.response.balance == null ? "" : res.data.response.balance;
                        this.setState({ maxAmount: parseInt(project.balance.split(/\s+/)[0]), project: project, loading: false });

                    });
                } catch (err) {
                    console.warn(err.toString());
                    this.props.history.goBack();
                }
            }
            ).catch(err => this.props.history.goBack());
            //localStorage.removeItem("projectId");
        }
    }
    calculatePrice = percent => {
        this.setState({ amount: this.state.maxAmount * percent });
    }

    /**
     * Checks if the given string is an address
     *
     * @method isAddress
     * @param {String} address the given HEX adress
     * @return {Boolean}
    */
    isAddress = function (address) {
        if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
            // check if it has the basic requirements of an address
            return false;
        } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
            // If it's all small caps or all all caps, return true
            return true;
        } else {
            // Otherwise check each case
            return this.isChecksumAddress(address);
        }
    };

    /**
     * Checks if the given string is a checksummed address
     *
     * @method isChecksumAddress
     * @param {String} address the given HEX adress
     * @return {Boolean}
    */
    isChecksumAddress = function (address) {
        // Check each case
        address = address.replace('0x', '');
        var addressHash = keccak256(address.toLowerCase());
        for (var i = 0; i < 40; i++) {
            // the nth letter should be uppercase if the nth digit of casemap is 1
            if ((parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) || (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])) {
                return false;
            }
        }
        return true;
    };
    validateWithdraw = () => {
        this.setState({ errAmount: "", errRcv: "" }, () => {
            let valid = true;
            let errors = {};
            if (this.state.rcv == "" && !this.isAddress(this.state.rcv)) {
                valid = false;
                errors["errRcv"] = "Please enter recipient address";
            }
            if (this.state.amount == "") {
                valid = false;
                errors["errAmount"] = "Please enter a amount";
            } else if (parseInt(this.state.amount) > this.state.maxAmount) {
                valid = false;
                errors["errAmount"] = "Insufficient balance";
            } else if (parseInt(this.state.amount) <= 0) {
                valid = false;
                errors["errAmount"] = "Please enter a positive value";
            }
            this.setState(errors);
            if (!valid) return;
            document.getElementById("withDrawModal").click();
        })
    }
    projectStyle = {
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        'min-height': '250px',
        justifyContent: 'center',
        alignItems: 'center'

    }
    render() {
        if (this.state.loading) return <Loader />;
        let noFAJSX = <div style={this.projectStyle} className="col">
            <img src={noProjectsIcon} style={{ marginTop: "30px" }} width="200px" />
            <span style={{ fontSize: "20px", fontWeight: '500' }}>2FA Not Setup</span>
            <span>2FA is not enabled. Withdrawing requires 2FA Enabled</span>
            <div className=" button-wrapper w-80 " style={{ marginTop: "40px", marginBottom: "40px" }}>
                <button type="button" onClick={() => { this.props.history.push("/security") }} style={{ "padding": "10px 25px" }}
                    className=" btn btn-primary w-80">Go to Security Management</button>
            </div>
        </div>
        return (<div id="wallet-wrapper" style={{ width: "100%" }}>
            <div className="wallet-withdraw withdraw-BTC">
                <div className="center-content w-100">
                    {!this.props.faEnabled? noFAJSX : [
                        <h2 className="text-center">Withdraw {this.state.project.symbol}</h2>,
                        <div className="inner-padding">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label for="recipient">Recipient</label>
                                    <input type="text" value={this.state.rcv} onChange={e => this.setState({ rcv: e.target.value })} className="form-control" id="recipient" placeholder={`Please input ${this.state.project.symbol} address`} />
                                    <label style={{ color: "red", fontSize: "12px" }}>{this.state.errRcv}</label>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-12 input-group">
                                    <label for="amount" className="d-block w-100">Amount</label>
                                    <input type="number" className="form-control" value={this.state.amount} onChange={e => {e.target.value.match(/^([0-9]+(\.[0-9]{1,3})?)?$/) && this.setState({ amount: e.target.value })}} placeholder="Amount"
                                        aria-label="Text input with dropdown button" />

                                    <div className="input-group-append">
                                        <button className="btn btn-secondary" type="button" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">{this.state.project.symbol}</button>

                                    </div>
                                    <label className="d-block w-100" style={{ display: "block", color: "red", fontSize: "12px" }}>{this.state.errAmount}</label>


                                </div>

                            </div>
                            <ul className="withdraw-list">
                                <li><a onClick={() => this.calculatePrice(25 / 100.00)} >25%</a></li>
                                <li><a onClick={() => this.calculatePrice(50 / 100.00)} >50%</a></li>
                                <li><a onClick={() => this.calculatePrice(75 / 100.00)}>75%</a></li>
                                <li><a onClick={() => this.calculatePrice(100 / 100.00)}>100%</a></li>
                            </ul>
                            <p>Available Balance: <strong>{this.state.project.balance} ~ {this.state.project.balance.split(/\s+/)[0]} USD</strong></p>
                            <button type="button" onClick={this.validateWithdraw}
                                className="btn btn-primary w-100 mb-10">Withdraw</button>
                            <button data-toggle="modal" data-target="#exampleModalCenter" hidden id="withDrawModal"></button>
                            <button type="button" hidden data-toggle="modal" data-target="#email-modal" id="emailModal" className="btn btn-primary w-100">
                                Email Modal
            </button>

                        </div>]}
            </div>
                <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header p-relative">
                                <h5 className="modal-title" id="exampleModalLongTitle">Confirm Withdrawl</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span className="x-icon" aria-hidden="true">&times;</span></button>
                                <button type="button" hidden id="dismissConfirm" className="close" data-dismiss="modal" aria-label="Close">
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>To:<strong>{this.state.rcv}</strong></p>
                                <p>Amount:<strong>{this.state.amount}</strong></p>
                                <p>Fasset Fee:<strong>0.00000 {this.state.project.symbol}</strong></p>
                                <p>Total:<strong>{this.state.amount} {this.state.project.symbol}</strong></p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-toggle="modal" data-target="#authentication-modal"
                                    className="w-100 btn btn-primary" onClick={() => document.getElementById("dismissConfirm").click()}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="modal fade authentication-modal" id="authentication-modal" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header p-relative">
                                <button type="button" className="close" id="otpClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="card mt-3 tab-card">
                                    <div className="card-header tab-card-header">
                                        <ul className="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                                            <li className="nav-item ">
                                                <a className="nav-link active" id="one-tab" onClick={this.handleGAuth} data-toggle="tab" href="#one" role="tab"
                                                    aria-controls="One" aria-selected="true">Google Authentication</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="two-tab" data-toggle="tab" value={this.state.otp} onClick={this.requestOtp} role="tab" aria-controls="Two"
                                                    aria-selected="false">SMS Authentication</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content" id="myTabContent">
                                        {this.state.otpLoading ? <Loader /> : [
                                            <div className="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="one-tab">
                                                <p className="card-text">{this.state.requestMsg}</p>
                                                <div className="partition">
                                                    <label for="verify">Verification Code</label>
                                                    <input id="verify" className="partitioned" value={this.state.otp} onChange={this.handleOtp} type="text" maxLength="6" />
                                                </div>
                                            </div>,
                                            <div className="tab-pane fade p-3" id="two" role="tabpanel" aria-labelledby="two-tab">
                                                <p className="card-text">{this.state.requestMsg}
                                                </p>
                                                <div className="partition">
                                                    <label for="mbl-verify">Verification Code</label>
                                                    <input id="mbl-verify" className="partitioned" type="text" onChange={this.handleOtp} maxLength="6" />
                                                </div>
                                            </div>]}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-0" style={{ "background": "red", "color": "white", "padding": "20px", "font-size": "14px", opacity: (this.state.withdrawErr == "" ? "0" : "1"), transition: "all 0.25s" }}>
                                Error: {this.state.withdrawErr}
                            </div>
                        </div>
                    </div>
                </div>



                <div className="modal fade email-modal" id="email-modal" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span></button>
                                <img src="images/email-icon.png" alt="Email Verification required icon" />
                                <h5 className="modal-title" id="exampleModalLongTitle">Email Verification Needed</h5>
                                <p className="card-text">We have sent an email to your registered email address: {this.props.email}</p>
                                <p className="card-text mb-20">Please then click on the confirmation link in your email.</p>
                                <button type="button" className="btn btn-primary mb-20">Go to Email and Verify</button>
                            </div>
                            <div className="modal-body">
                                <p className="card-text">If you haven't received the email yet,please try the following.</p>
                                <ul>
                                    <li>Make sure the email address you provided is correct.</li>
                                    <li>Check your spam or Junk mail folders.</li>
                                    <li>Add Fasset to your email address wishlist. <a href="#">Learn more</a></li>
                                    <li>Change email. <a href="#">How to change email provider?</a></li>
                                    <li>Make sure your email is functioning normally.</li>
                                    <span className="timer">Resend Email(01:08s)</span>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default Withdraw;