import React from 'react';
import './Overview.css';
import DataService from "../../DataService/DataService";
import Loader from '../../Components/Loader/Loader';
import noProjectsIcon from '../../Assets/images/no-projects.svg';
import ReactTooltip from "react-tooltip";

export default class Overview extends React.Component {
  constructor() {
    super();
    this.state = { projectListings: [], isDataLoaded: false };
  }
  componentDidMount() {
    this.loadProjectListings();
  }

  loadProjectListings() {
    DataService.getProjectListings().then(res => {
      let projects = res.data.response.data;
      let promises = [];
      projects.forEach(project => {
        if (project.status.name == "Tokenized" || project.status.name == "Withdraw") {
          let promise = DataService.getProjectBalance(project.id);
          promises.push(promise);
          promise.then(balance => project.balance = balance.data.response.balance == null ? "" : balance.data.response.balance);
        } else {
          project.balance = "";
        }

      })
      Promise.all(promises).then(res => this.setState({ projectListings: projects, isDataLoaded: true }))
    });
    Promise.all([
      DataService.getUserDocumentConfig(),
      DataService.getUserDocuments()
    ]).then(responses => {
      let requiredDocumentConfigs = responses[0].data.response.filter(d => d.isRequired == 1 || true);
      let requiredDocument = responses[1].data.response.filter(d => d.documentConfiguration.isRequired == 1 || true);

      if (requiredDocument.length === requiredDocumentConfigs.length) this.setState({ documentVerified: true });
      else this.setState({ documentVerified: false });
    })
  }
  projectStyle = {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    'min-height': '250px',
    justifyContent: 'center',
    alignItems: 'center'
  }
  commaSeparated(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {

    if ((this.state.isDataLoaded && this.state.projectListings.length == 0)) {
      localStorage.removeItem("firstLogin");
      document.getElementById("welcomeModalOpen").click();
    }
    if (this.state.isDataLoaded) {
      let noProjectsJSX = <div style={this.projectStyle} className="col">
        <img src={noProjectsIcon} style={{ marginTop: "30px" }} width="200px" />
        <span style={{ fontSize: "20px", fontWeight: '500' }}>No projects</span>
        <span>Looks like we have no projects. Lets tokenize one!</span>
        <div className=" button-wrapper w-80  " style={{ marginTop: "40px", marginBottom: "40px" }}>
          <button type="button" onClick={() => { localStorage.removeItem("projectId"); window.location.href = "/tokenize"; }} style={{ "padding": "10px 25px" }}
            className=" btn btn-primary w-80">Start Tokenization</button>
        </div>
      </div>
      return (
        <div className="center-content">
          <div class="project-cards">
            <div class="row">
              <div class="col-md-8">
                <div class="view native-token-balance-view">
                  <div class="row">
                    <div class="col-sm-6">
                      <h4>Native Token Balance</h4>
                    </div>
                    <div class="col-sm-6" align="right">
                      <a onClick={() => this.props.history.push("/wallet")} class="" style={{ color: '#0099a1' }}>View Token Balance &gt;</a>
                    </div>
                  </div>
                </div>
                {this.state.projectListings.length != 0 ?
                  <div class="inner-padding box-shadow" >
                    {
                      this.state.projectListings.map((value, index) => {
                        let attributes = value.balance == "" ? { "data-for": "main", "data-tip": "Awaiting Tokenization" } : {};
                        return (
                          <div class="heading-wrapper" style={{ position: "relative" }}>
                            {value.status.name.toLowerCase() === "inprogress" ?
                              <div class="overview-project-overlay">
                                <button className="btn btn-primary" onClick={() => { localStorage.setItem("projectId", value.id); window.location.href = value.status.name == "InProgress" ? "/tokenize" : `/myprojects/detail/${value.id}`; }} >{value.status.name == "InProgress" ? "Continue" : "View Project"}</button>
                              </div> : ""}
                            <h7 style={{ flex: '1' }}>{value.name}<p style={{ fontSize: '15px' }}>{value.symbol}</p>
                            </h7>
                            <strong style={{ flex: '2',...value.status.name.toLowerCase() === "inprogress"? {color:"red"}:{color:"#228B22"	}, textAlign: 'left', fontSize: '12px', paddingLeft: "30px" }}>{(value.status.name.toLowerCase() == "inprogress" ? "In Progress" : (value.status.name))}</strong>
                            <div class="button-wrapper">
                              <span style={{ zIndex: 9999 }} {...attributes}><button type="button" disabled={value.balance == ""} onClick={() => { localStorage.setItem("projectId", value.id); this.props.history.push("/wallet/transfer") }} class="btn btn-secondary">Withdraw</button></span>
                              <button type="button" class="btn btn-primary card-overlay" style={{ position: "relative", borderColor: "#008787", border: "none" }}>Offering</button>
                            </div>
                            <ReactTooltip id="main" />
                          </div>
                        )
                      })
                    }
                    <button type="button" onClick={() => { localStorage.removeItem("projectId"); window.location.href = "/tokenize"; }} class="btn btn-primary w-100">Add New Project</button>
                  </div> : noProjectsJSX}
              </div>

              <div class="col-md-4">
                <button type="button" onClick={() => { localStorage.removeItem("projectId"); window.location.href = "/tokenize"; }}
                  className=" tokenize-btn btn-token btn btn-primary w-100" style={{ "font-size": "13px !important" }} >Start Tokenization</button>
                <div class="view native-token-balance-view">
                  <a style={{ visibility: 'hidden' }}>×</a>
                </div>
                <div class="inner-padding content-right box-shadow">
                  <h5 class="bold">Finish setting up your account</h5>
                  <div class="content-infront">
                    <strong><span>1</span>Set up 2FA<p>Secure your account</p></strong>
                    {this.props.faEnabled ? <i class="fa fa-check-circle-o pr-4" aria-hidden="true"></i>
                      : <button type="button" onClick={() => { this.props.history.push("/security") }} class="btn btn-primary">Setup</button>}
                  </div>
                  <div class="content-infront card-overlay" style={{ "position": "relative" }}>
                    <strong><span>2</span>Add sub account<p>Add more sub account for your team</p></strong>
                    <button type="button" class="btn btn-primary">Add</button>
                  </div>

                  <div class="content-infront">
                    <strong><span>3</span>Document Verification<p>Attach KYC Documents</p></strong>
                    {this.state.documentVerified ? <i class="fa fa-check-circle-o pr-4" aria-hidden="true"></i>
                      : <button type="button" onClick={() => { this.props.history.push("/document-verification") }} class="btn btn-primary">Upload</button>}
                  </div>
                </div>
              </div>
            </div>
            {this.state.projectListings.length == 0 ? "" :
              <div class="balance-details mt-30">
                <div class="row">
                  <div class="col-md-12 col-lg-6" style={{ position: "relative", pointerEvents: 'none' }}>
                    <div style={{ backgroundColor: 'white' }}>
                      {/* <h5 class="native-token-balance-h3" style={{ opacity: '0.3', marginLeft: '13px' }}>Proceeds Balance</h5> */}
                    </div>
                    <div class="view" align="right">
                      <div class="row">
                        <div class="col-sm-6 text-left" style={{ marginTop: '-8px' }}>
                          <h5 class="native-token-balance-h3"  style={{ color: '#b8b8b8' }}>Proceeds Balance</h5>
                        </div>
                        <div class="col-sm-6" align="right">
                          <a href="#" style={{ color: '#b8b8b8' }}>View breakdown &gt;</a>
                        </div>
                      </div>
                    </div>
                    <div class="inner-padding box-shadow" style={{ backgroundColor: 'white' }}>
                      <div class="text-wrapper" style={{ opacity: '0.3' }}>
                        <span>Total Project Proceeds<p>0,0000,0000 USD</p>
                        </span>
                      </div>
                      {/* <!-- Budget Details --> */}
                      <div class="row" style={{ opacity: '0.3' }}>
                        <div class="content-job">
                          <div class="chart-disc">
                            <i class="fa fa-square-full"></i>
                            <span>USD</span>
                            <strong>0,000,000 USD (60%)</strong>
                          </div>
                          <div class="chart-disc BTC">
                            <i class="fa fa-square-full BTC"></i>
                            <span>USD</span>
                            <strong>0.0000 = 0,000,000 USD (25%)</strong>
                          </div>
                          <div class="chart-disc BTH">
                            <i class="fa fa-square-full BTH"></i>
                            <span>USD</span>
                            <strong>0.0000 = 0,000,000 USD (15%)</strong>
                          </div>
                        </div>
                        <div><img style={{ width: '140px' }} src="images/graph-50.png" alt="" /></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12  col-lg-6 d-flex flex-column">
                    {/* <h5 class="native-token-balance-h3">My Projects</h5> */}
                    <div class="view" align="right">
                      <div class="row">
                        <div class="col-sm-6 text-left" style={{ marginTop: '-8px' }}>
                          <h5 class="native-token-balance-h3">My Projects</h5>
                        </div>
                        <div class="col-sm-6" align="right">
                          <a onClick={() => { this.props.history.push("/myprojects") }} style={{ color: '#0099a1' }}>View Projects &gt;</a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Documentation Section --> */}
                    <div class="document-section" style={{ margin: '0px' }}>
                      <div class="row">
                        {
                          this.state.projectListings.length > 0 ?
                            this.state.projectListings.map((value, index) => {
                              return (
                                <div class="col-md-6 cardOverlay" style={{ margin: '0px' }}>
                                  <div className="cards" style={{ width: 'auto', height: '15rem' }}>
                                    <div className="card-block">
                                      <div className="value.status.id == 2 ? inProgressLabelDiv : doneLabelDiv ">
                                        <span style={{ padding: "5px 15px" }} className={"float-right " + (value.status.name.toLowerCase() == "inprogress" ? "inprocess" : "complete")} className="labelSpan">
                                          {value.status.name === "InProgress" ? "In Progress" : value.status.name}
                                        </span>
                                      </div>
                                      <div style={{ marginTop: '25px', marginLeft: '30px', fontSize: '14px' }}>
                                        <p style={{ color: 'black' }}>{value.name}
                                          <p style={{ color: '#999', fontSize: '12px' }}> {value.symbol} </p>
                                        </p>
                                      </div>
                                      <div style={{ marginTop: '50px', marginLeft: '20px', width: '85%' }}>
                                        <p style={{ color: 'lightblue', fontSize: '12px', margin: '0px' }}>
                                          {value.lastStepCompleted === 1 ? '20%' : (value.lastStepCompleted === 2 ? '80%' : '100%')} Completed
                                  </p>
                                        <div class="progress">
                                          <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                                            style={{ borderRadius: '0%', width: value.lastStepCompleted === 1 ? '20%' : (value.lastStepCompleted === 2 ? '80%' : '100%') }}>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="middle" data-tip data-for="toolTip">
                                    <ReactTooltip id="toolTip" style={{ backgroundColor: 'black' }}>
                                      <b>{value.name} <p style={{ fontSize: '11px', color: 'white' }}>{value.symbol}</p></b>
                                    </ReactTooltip>
                                    <button onClick={() => {
                                      if (value.status.id == 2) {
                                        localStorage.setItem("projectId", value.id);
                                        window.location.href = "/tokenize";;
                                      } else {
                                        this.props.history.push(`/myprojects/detail/${value.id}`);
                                      }
                                    }} style={{ backgroundColor: '#0099a1', borderColor: '#049ca7', borderRadius: '0%', height: '50px' }} type="button" class="btn btn-primary">
                                      <font style={{ fontFamily: 'sans-serif' }}>
                                        {value.status.id === 2 ? 'Continue' : 'View Project'}
                                      </font>
                                    </button>
                                  </div>
                                </div>
                              )
                            }) :
                            <div class="col" align="center">
                              <img src={noProjectsIcon} style={{ marginTop: "40px" }} width="200px" />
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="container" style={{ width: '30%', textAlign: 'center' }}>
          <Loader />
        </div>
      );
    }
  }
}
